/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { genericErrorMessage } from 'app/store/briisk/actions/formAction';
import axios from 'axios';
import { parameters } from 'api/parameter';
import { productPriceRequestBody } from './ProductOptionHelper';
import { getFactors, getRisks } from './CreatePolicyHelper';
import store from 'app/store';
import { resumePolicyPathNames } from './ResumePolicyHelper';
import { ENDORSABLE_FLAG } from 'utils/enums';
import { getDisplayOneOptionAtTimeDetails } from './GenericHelper';

export const isRiskExcluded = (channelProducts, productInstanceId, productOptionInstanceId, riskPageNo) => {
	const selectedProduct = channelProducts?.value?.find(prod => prod.product.instanceId === productInstanceId);
	const selectedProductOptionExcludedRisks = selectedProduct?.options
		?.find(x => x?.option?.instanceId === productOptionInstanceId)
		?.option?.excludedRisks?.split(',');
	const currentRiskPage = selectedProduct?.risks[riskPageNo - 1];
	const isExcludedRisk = selectedProductOptionExcludedRisks?.includes(currentRiskPage?.code);
	return isExcludedRisk;
};

export const getRiskByRiskIndex = (currentRiskIndex, channelProducts, productId, source = 1) => {
	const { formValues } = store.getState().fuse.form;
	const selectedProduct = channelProducts?.value?.find(prod => prod.product.instanceId === productId);
	// Onboarding
	if (source === 1) {
		return selectedProduct?.risks[currentRiskIndex];
	}
	// Claims
	else if (source === 2) {
		return selectedProduct?.claims.find(claims => claims.instanceId === formValues.claimFlowInstanceId)?.claimForm[
			currentRiskIndex
		];
	} else {
		return {};
	}
};

export const getFactorHeaders = (channelProducts, productId) => {
	let factorHeadingText = '';
	let factorDescriptionText = '';
	const specificProductContent = channelProducts?.value?.find(
		prod => prod.product.instanceId === productId
	)?.contents;
	factorHeadingText = specificProductContent?.find(content => content.key === 'FACTORS-HEADING-TEXT')?.value;
	factorDescriptionText = specificProductContent?.find(content => content.key === 'FACTORS-DESCRIPTION-HTML')?.value;
	return { factorHeadingText, factorDescriptionText };
};

// Gives the current source required details
// Source - 1 = Onboarding, 2 = Claims
export const getCurrentSourceDetails = ({ source, channelProducts, formValues }) => {
	const details = {
		addFirstRiskName: '',
		risks: []
	};

	const selectedProduct = channelProducts?.find(e => e?.product?.instanceId === formValues?.productInstanceId);
	const selectedClaimFlow = selectedProduct?.claims?.find(e => e?.instanceId === formValues?.claimFlowInstanceId);

	// Onboarding
	if (source === 1) {
		details.addFirstRiskName = selectedProduct?.risks[`${formValues?.riskPageNo - 1}`]?.name;
		details.risks = selectedProduct ? [...(selectedProduct?.risks || [])] : [];
	}
	// Claims
	else if (source === 2) {
		details.addFirstRiskName = selectedClaimFlow?.claimForm[`${formValues?.riskPageNo - 1}`]?.name;
		details.risks = selectedClaimFlow ? [...(selectedClaimFlow?.claimForm || [])] : [];
	}

	return { ...details };
};

// To figure out when to show the current risk.
// For instance, we might display Assessment Risks when the underwriter is reviewing a referred policy.
// For Benefit or Cover Risks, we'd show them only during an endorsement process.
export const canCurrentRiskBeDisplayed = ({
	draftPolicy,
	resumePolicyPathNames,
	currentRiskFormType,
	currentRiskRiskType
}) => {
	// FormType = 2 is Assessment Form Risk.
	if (currentRiskFormType === 2) {
		// We have to display Assessment Form Risk only during under writer continuing referred policy.
		if (draftPolicy?.draftType === resumePolicyPathNames.resumeUWCReferredPolicy) {
			return true;
		} else {
			return false;
		}
	}

	// RiskType = 1 is Cover Risk & RiskType = 2 is Benefit Risk.
	if (currentRiskRiskType === 1 || currentRiskRiskType === 2) {
		// We have to display Cover Risk or Benefit Risk only during under writer continuing endorsement.
		if (draftPolicy?.draftType === resumePolicyPathNames.resumeUWCEndorsement) {
			return true;
		} else {
			return false;
		}
	}

	return true;
};

// #region Setting up Initial Values

// To get initial Factor's / Risk's Default Values...
// Note - Should be called only from QuickQuote page.
export const getInitialDefaultValues = (prefixName, currentAttribute, values, phoneCodeDefaultValue) => {
	let newValues = {};
	// Assigning default values...
	if (currentAttribute?.defaultValue) {
		if (!values[`${prefixName}-${currentAttribute?.name}`]) {
			newValues = { ...newValues, [`${prefixName}-${currentAttribute?.name}`]: currentAttribute?.defaultValue };
		}
	}
	// Assigning default phone code...
	if (currentAttribute?.dataType === 13 || currentAttribute?.dataType === 14) {
		if (!values[`${prefixName}-${currentAttribute?.name}Code`]) {
			newValues = {
				...newValues,
				[`${prefixName}-${currentAttribute?.name}Code`]: phoneCodeDefaultValue || '27'
			};
		}
	}
	return newValues;
};

// To get Risk's Default Values...
// Note - Should be called only from Risk Info page.
export const getRisksDefaultValues = (prefixName, currentRiskRisks, values) => {
	const currentRisk = currentRiskRisks[prefixName];
	// Getting phone number's default code...
	const phoneCodeDefaultValue = currentRisk?.attributes?.find(e => e.dataType === 12)?.defaultValue;
	let newValues = {};
	currentRisk?.attributes?.forEach(attribute => {
		// Assigning default values...
		if (attribute?.defaultValue) {
			if (!values[`${prefixName}-${attribute?.name}`]) {
				newValues = { ...newValues, [`${prefixName}-${attribute?.name}`]: attribute?.defaultValue };
			}
		}
		// Assigning default phone code...
		if (attribute?.dataType === 13 || attribute?.dataType === 14) {
			if (!values[`${prefixName}-${attribute?.name}Code`]) {
				newValues = { ...newValues, [`${prefixName}-${attribute?.name}Code`]: phoneCodeDefaultValue || '27' };
			}
		}
	});
	return newValues;
};

// #endregion

// #region Handling Dependencies & Linked Fields

export const getRiskCodeFromAttributeCode = attributeCode => {
	const riskCode = attributeCode?.split('-')?.slice(0, -1)?.join('-');
	return riskCode;
};

// If we would have to get value only from factors, do not pass first param. Pass null.
export const getAttributeValueByCode = (prefix, attributeCode, values, risks, factors) => {
	if (!attributeCode) {
		return {
			value: null
		};
	}
	// Factors...
	const factorAttribute = getFactors({ ...values }, factors)?.find(y => y.code === attributeCode);
	// #region Risks
	const riskCode = getRiskCodeFromAttributeCode(attributeCode);
	// Getting risk by riskCode and by it's any index...
	const risksRiskByIndex =
		getRisks({ ...values }, risks, null)
			?.filter(x => x.code === riskCode)
			[Number(prefix?.split('-')[2])]?.attributes?.find(y => y.code === attributeCode) || null;
	// Getting risk by riskCode and by it's 1st index...
	const risksRiskByFirstIndex = getRisks({ ...values }, risks, null)
		?.find(x => x.code === riskCode)
		?.attributes?.find(y => y.code === attributeCode);
	// #endregion
	return factorAttribute ?? risksRiskByIndex ?? risksRiskByFirstIndex;
};

const compareDependencies = (prefix, dependencies, values, risks, factors) => {
	let isDisabled = true;
	dependencies?.forEach(dependency => {
		// The destination value that we have to compare with.
		const dependentOnValue = getAttributeValueByCode(
			prefix,
			`${dependency?.name?.code}`,
			values,
			risks,
			factors
		)?.value;

		switch (dependency?.rule?.type) {
			// Do the comparison only when the isDisabled is true.
			// Because as per the requirement, we have to perform only OR condition.
			// So that, once the isDisabled becomes false, do not check any other conditions.
			case 1: // ==
				if (isDisabled) {
					dependentOnValue?.toString()?.toLowerCase() === dependency?.value?.toString()?.toLowerCase()
						? (isDisabled = false)
						: (isDisabled = true);
				}
				break;
			case 6: // !=
				if (isDisabled) {
					dependentOnValue?.toString()?.toLowerCase() !== dependency?.value?.toString()?.toLowerCase()
						? (isDisabled = false)
						: (isDisabled = true);
				}
				break;
			default:
				break;
		}
	});
	return isDisabled;
};

export const handleDynamicFieldsChange = ({ values, risks, factors, attachments = null }) => {
	const draftType = store.getState().fuse.form.draftPolicy.draftType;
	const displayOneOptionAtATime = getDisplayOneOptionAtTimeDetails(values?.productInstanceId);
	// Loop through the factors and disabling the attributes if needed
	factors &&
		Object.keys(factors || {}).forEach(prefix => {
			const clonedAttributes = factors[prefix] ? [...(factors[prefix] || [])] : [];
			clonedAttributes?.forEach(clonedAttribute => {
				// This if statement will execute only if the draftType is resumeUWCEndorsement.
				// It will disable the field if the factor or clonedAttribute endorsable is READ_ONLY.
				if (draftType === resumePolicyPathNames.resumeUWCEndorsement) {
					if (clonedAttribute?.endorsable === ENDORSABLE_FLAG.READ_ONLY) {
						values[`${prefix}-${clonedAttribute?.name}-disable`] = true;
					}
				}
			});
		});

	risks?.forEach(risk => {
		risk &&
			Object.keys(risk).forEach(prefix => {
				const clonedAttributes = risk[prefix] ? [...(risk[prefix]?.attributes || [])] : [];
				clonedAttributes?.forEach(clonedAttribute => {
					// Handling dependencies...
					if (clonedAttribute?.dependencies && JSON.parse(clonedAttribute?.dependencies)?.items?.length > 0) {
						const dependencies = JSON.parse(clonedAttribute?.dependencies)?.items;
						const isDisabled = compareDependencies(prefix, dependencies, values, risks, factors);
						values = { ...values, [`${prefix}-${clonedAttribute?.name}-disable`]: isDisabled };

						// If isDisabled is true, then removing the field's value from the formValues.
						if (isDisabled) {
							values = { ...values, [`${prefix}-${clonedAttribute?.name}`]: null };
						}
					}

					// Handling linked fields...
					if (clonedAttribute?.linkedField) {
						// Disabling the field.
						if (values[`${prefix}-${clonedAttribute?.name}-disable`] !== true) {
							values[`${prefix}-${clonedAttribute?.name}-disable`] = true;
						}
						// Linking the value.
						const linkedOnCode = clonedAttribute?.linkedField?.split('~')[1]?.trim();
						const linkedOnValue = getAttributeValueByCode(
							prefix,
							linkedOnCode,
							values,
							risks,
							factors
						)?.value;
						if (values[`${prefix}-${clonedAttribute?.name}`] !== linkedOnValue) {
							values[`${prefix}-${clonedAttribute?.name}`] = linkedOnValue;
						}
					}

					// Handling disable lookup fields.
					if (clonedAttribute?.lookUps) {
						const { Disabled: disabled } = JSON.parse(clonedAttribute?.lookUps) || {};
						// If Disabled is true from the lookUps, then disabling the field.
						if (disabled) {
							values[`${prefix}-${clonedAttribute?.name}-disable`] = disabled;
						}
					}

					// This if statement will execute only if the draftType is resumeUWCEndorsement.
					// It will disable the field if the risk or clonedAttribute endorsable is READ_ONLY.
					if (draftType === resumePolicyPathNames.resumeUWCEndorsement) {
						if (
							clonedAttribute?.endorsable === ENDORSABLE_FLAG.READ_ONLY ||
							risk[prefix]?.endorsable === ENDORSABLE_FLAG.READ_ONLY
						) {
							values[`${prefix}-${clonedAttribute?.name}-disable`] = true;
						}
					}
				});
				// Handling attachment dependencies...
				attachments?.attachments?.forEach(attachment => {
					if (attachment?.dependencies && JSON.parse(attachment?.dependencies)?.items?.length > 0) {
						const dependencies = JSON.parse(attachment?.dependencies)?.items;
						const isRequired = !compareDependencies(prefix, dependencies, values, risks, factors);
						values = { ...values, [`${attachment.instanceId}-required`]: isRequired };
					}
				});
			});
	});
	// Handling attachments required validations...
	attachments?.attachments?.forEach(attachment => {
		values = { ...values, [`${attachment.instanceId}-required`]: attachment?.isRequired };
	});

	// Handling the default value for displayOneOptionAtATime
	if (displayOneOptionAtATime.displayOneOption && !values?.OneOptionDisplayValue) {
		values = {
			...values,
			OneOptionDisplayValue: Number(displayOneOptionAtATime.defaultValue)
		};
	}
	return { ...values };
};

export const handleDynamicErrorChange = ({ values, errorObj }) => {
	if (!errorObj) return errorObj;
	// eslint-disable-next-line prefer-const
	let clonedErrorObj = { ...errorObj };
	Object?.keys(errorObj).forEach(key => {
		if (values[`${key}-disable`] || values[`${key}-disable-taken-down`]) {
			delete clonedErrorObj[`${key}`];
		}
	});
	return clonedErrorObj;
};

// #endregion

// #region On click next button validations...

export const isRiskAddButtonDisabled = (currentRisks_Risks, linkedAttributeCode, values, risks, factors) => {
	// When the "Not More Than Linked Attribute" is not linked, enable the Add button.
	if (!linkedAttributeCode) return false;
	// When the "Not More Than Linked Attribute" is linked,
	const attributeValue = getAttributeValueByCode(null, linkedAttributeCode, values, risks, factors)?.value;
	// If no value is entered on the linked attribute, always disable the Add button.
	if (!attributeValue) return true;
	// If the entered value on the linked attribute is 0, even then disable the Add button.
	if (parseInt(attributeValue, 10) === 0) return true;
	// Before the Risk gets added for the first time &&
	// When the Risk is linked to "Not More Than Linked Attribute" &&
	// When the user enters the value apart from 0, enable the Add button.
	if (!currentRisks_Risks && linkedAttributeCode && attributeValue) {
		return false;
	}
	// When the count of current Risk's -> Risks is >= the total count
	// of the user entered value on Linked field, disable the Add button.
	if (Object?.keys(currentRisks_Risks)?.length >= parseInt(attributeValue, 10)) return true;
	// else enable it all the time.
	return false;
};

// Returns true, if isNotMoreThanLinkedAttributeLinked is linked.
// Returns false, if isNotMoreThanLinkedAttributeLinked is not linked.
export const isNotMoreThanLinkedAttributeLinked = (channelProducts, productId, currentRiskIndex) => {
	const notMoreThanLinkedAttribute = channelProducts?.value?.find(
		channelProduct => channelProduct?.product?.instanceId === productId
	)?.risks[currentRiskIndex]?.notMoreThanLinkedAttribute;
	return !!notMoreThanLinkedAttribute;
};

// Params,
// currentRiskFromCP = Current Risk from Channel Product.
// currentRisks_Risks = Current Risks of Risk.
// values = All the user entered values.
// risks = All risks.
// factors = All factors.
const validateNotMoreThanHelper = (currentRiskFromCP, currentRisks_Risks, values, risks, factors) => {
	let errorObj = {};
	const notMoreThanLinkedAttribute = currentRiskFromCP?.notMoreThanLinkedAttribute;
	if (notMoreThanLinkedAttribute) {
		// Current Risk's -> Risks count...
		const currentRisks_Risks_Count = Object.keys(currentRisks_Risks || {})?.length;
		// Getting linked field value...
		// As we have to fetch linked value only from factors, hence don't have to pass first param.
		const attributeValue = getAttributeValueByCode(
			null,
			notMoreThanLinkedAttribute?.split('~')[0]?.trim(),
			values,
			risks,
			factors
		)?.value;
		// When the count of current Risk's -> Risks is greater than or lesser than the total count of the user entered value on Linked field...
		if (
			currentRisks_Risks_Count > parseInt(attributeValue, 10) ||
			currentRisks_Risks_Count < parseInt(attributeValue, 10)
		) {
			const errorMessage = `${currentRisks_Risks_Count} ${currentRiskFromCP?.name}(s) are added instead of ${attributeValue}`;
			errorObj = { ...errorObj, [currentRiskFromCP?.name]: [errorMessage] };
			toast.error(`${errorMessage}`, {
				autoClose: 10000
			});
		}
		// When current Risk's -> Risks are added when no value entered on Linked field...
		else if (currentRisks_Risks_Count > 0 && !attributeValue) {
			const errorMessage = `Invalid ${currentRiskFromCP?.name}(s). Either remove all ${
				currentRiskFromCP?.name
			}(s), nor enter correct ${notMoreThanLinkedAttribute?.split('~')[1]?.trim()}`;
			errorObj = { ...errorObj, [currentRiskFromCP?.name]: [errorMessage] };
			toast.error(`${errorMessage}`, {
				autoClose: 10000
			});
		}
	}
	return errorObj;
};

export const validateUniqueAttributesHelper = (currentRiskFromCP, values, risks) => {
	let errorObj = {};
	const risksWithValues = getRisks({ ...values }, risks, null)?.filter(x => x.code === currentRiskFromCP?.code);
	const uniqueAttributes = [...(currentRiskFromCP?.attributes?.filter(x => x.uniqueFlag === 'true') ?? [])];
	uniqueAttributes.forEach((uniqueAttribute, i) => {
		const anArrayOfValues = risksWithValues
			?.map(e => e?.attributes?.filter(x => x?.code === uniqueAttribute?.code)?.map(o => o.value))
			?.map(v => v[0]);

		// Check if all values in the anArrayOfValues are equal to undefined
		// There's no need to validate the attribute if it's null or undefined.
		const allUndefined = anArrayOfValues?.every(item => item === undefined || !item?.length);

		if (!allUndefined) {
			if (anArrayOfValues?.length !== new Set(anArrayOfValues?.map(val => val?.toString()?.toLowerCase())).size) {
				const errorMessage = `${uniqueAttribute?.name} should be unique.`;
				errorObj = { ...errorObj, [`${currentRiskFromCP?.name}-${i}`]: [errorMessage] };
				toast.error(`${errorMessage}`, {
					autoClose: 10000
				});
			}
		}
	});
	return errorObj;
};

export const handleOnClickNextValidation = ({
	channelProducts,
	selectedProductId,
	currentRiskPageIndex,
	isFromRiskInfo,
	values,
	risks,
	factors
}) => {
	let errorObj = {};
	// If this method has been called when the user is on Quick Quote page,
	// then we have to validate all the Risks.
	if (!isFromRiskInfo) {
		risks?.forEach((risk, i) => {
			const currentRiskFromCP = getRiskByRiskIndex(i, channelProducts, selectedProductId);
			if (currentRiskFromCP?.attributes?.find(e => e.quickQuote)) {
				errorObj = {
					...errorObj,
					...validateNotMoreThanHelper(currentRiskFromCP, risk, values, risks, factors)
				};
			}
		});
	}
	// If this method has been called when the user is on Risk Info page,
	// then we have to validate only the current Risk page.
	else {
		const currentRiskFromCP = getRiskByRiskIndex(currentRiskPageIndex, channelProducts, selectedProductId);
		errorObj = {
			...errorObj,
			...validateNotMoreThanHelper(currentRiskFromCP, risks[currentRiskPageIndex], values, risks, factors),
			...validateUniqueAttributesHelper(currentRiskFromCP, values, risks)
		};
	}
	return errorObj;
};

// #endregion

// #region Handling service risk...

export const getExecuteServiceRisksPayload = (risks, formValues, channelProducts, productId, productPriceNew) => {
	// the current risk page.
	const currRisk = { ...[...risks][`${formValues.riskPageNo - 1}`] };
	// selected product.
	const currProduct = channelProducts?.value?.find(
		channelProduct => channelProduct?.product?.instanceId === productId
	);
	// calculated price of all the options.
	const optionPrice = productPriceNew?.value?.productOptions?.filter(
		e => e.productOptionReference === formValues.productOptionInstanceId
	)[0];
	// all the risks page values.
	const risksWithValues = getRisks({ ...formValues }, risks, optionPrice);

	return {
		currRisk,
		currProduct,
		risksWithValues
	};
};

export const handleDependentServiceRiskResponse = (response, formValues) => {
	let serviceRisks = [];
	if (response?.updatedMapServiceRisksDtos) {
		if (formValues?.serviceRisks && formValues?.serviceRisks.length > 0) {
			serviceRisks = [
				// Removing the current Service Risks Info results of the current Service Risk Info page if any
				...(formValues?.serviceRisks
					? formValues.serviceRisks.filter(e => e.code !== response?.updatedMapServiceRisksDtos[0]?.code)
					: []),
				// Adding the updated Service Info Risks results of the current Service Risk Info page
				...(response?.updatedMapServiceRisksDtos || [])
			];
		} else {
			serviceRisks = [...response.updatedMapServiceRisksDtos];
		}
	} else {
		serviceRisks = formValues?.serviceRisks?.length > 0 ? [...formValues.serviceRisks] : [];
	}
	return serviceRisks;
};

// #endregion

const executeServiceRisksCall = requestBody => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${parameters.serviceUrl}/Product/ExecuteServiceRisks`, requestBody)
			.then(result => {
				resolve(result.data);
			})
			.catch(error => {
				reject(error);
			});
	});
};

// Dependent Service Risks are the one which needed a API call to verify/perform something. In return, API will return all the attributes with the values.
// Independent Service Risks are the one which does not need a API call. We just have to assign a value to all the attributes from their linked attribute.
export const executeDependentServiceRisks = async (currentRisk, serviceRisks, risksWithValues) => {
	return new Promise((resolve, reject) => {
		if (!currentRisk || !serviceRisks || serviceRisks.length <= 0) {
			resolve();
			return;
		}
		// if the service risks is three and the second service risk is executing the api call then forEach will not wait for the api, and it's execute the next iteration
		// isAnyServiceRiskExecuting used for restricting the resolve on the else condition
		// THIS IS TEMPORARY FIX, need to enhance the executions of the service risks
		let isAnyServiceRiskExecuting = false;
		serviceRisks.forEach(async (serviceRisk, index) => {
			// if any service risk is linked to the current risk page, then call api.
			if (serviceRisk?.code.split('-')[1] === Object.values(currentRisk)[0].code) {
				try {
					isAnyServiceRiskExecuting = true;
					const result = await executeServiceRisksCall({
						serviceRisk: { ...serviceRisk },
						risksWithValues: [...risksWithValues]
					});
					resolve(result || {});
				} catch (error) {
					reject(error);
				}
			}
			// if no service risk is linked to the current risk page, just continue.
			else if (serviceRisks.length - 1 === index && !isAnyServiceRiskExecuting) {
				resolve();
			}
		});
	});
};

export const enforceServerSideValidation = async (
	enforce = false,
	productsInfo,
	values,
	risks,
	factors,
	selectedProductOptionInstanceId
) => {
	return new Promise((resolve, reject) => {
		if (!enforce) {
			resolve({ isSuccess: true });
			return;
		}
		const requestBody = productPriceRequestBody(productsInfo, risks, factors, values);
		axios
			.post(`${parameters.serviceUrl}/GetProductPriceNew`, requestBody)
			.then(result => {
				if (result?.data) {
					const selectedProductOption = result?.data?.productOptions?.find(
						e => e?.productOptionReference === selectedProductOptionInstanceId
					);
					if (selectedProductOption?.rule?.isValid) {
						resolve({ isSuccess: true });
					} else {
						resolve({
							isSuccess: false,
							message: selectedProductOption?.rule?.message || 'Invalid details.'
						});
					}
				}
			})
			.catch(error => {
				resolve({ isSuccess: false, message: genericErrorMessage });
			});
	});
};
