import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useStylesNavBarLogo } from '../styles/NavbarStyles';
import { getLogo } from '../helpers/Generichelper';

function Logo({ className, logoWidth }) {
	const theme = useTheme();
	const classes = useStylesNavBarLogo(theme, logoWidth);
	const agentDetails = useSelector(({ fuse }) => fuse?.form?.referenceTokenDetails?.agentDetails);
	const companyDetails = useSelector(({ fuse }) => fuse?.form?.companyDetailsResponse?.value);
	const companyLogo = companyDetails?.companyLogoBlobUrl || companyDetails?.logoBlobUrl;

	const primaryLogo =
		agentDetails?.agentLogoType === 1 && agentDetails?.agentLogoBlobUrl
			? agentDetails?.agentLogoBlobUrl
			: getLogo()?.primaryLogo || companyLogo;

	return <>{primaryLogo && <img style={classes.logo} src={primaryLogo} alt="" />}</>;
}

Logo.propTypes = {
	className: PropTypes.string,
	logoWidth: PropTypes.string
};

export default Logo;
