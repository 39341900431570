export const useStyles = themeinfo => {
	return {
		secondFooterBgColor: {
			backgroundColor: themeinfo.footerSecondBackgroundColor || 'white'
		},
		thirdFooterBgColor: {
			backgroundColor: themeinfo.footerThirdBackgroundColor || 'white'
		}
	};
};
