import { isResumedPolicy } from './DraftPolicyHelper';

// Can be used outside buy insurance pages...
export const getNewBuyInsurancePathGeneric = (channelProducts, token, hostPrefix) => {
	const pathname =
		channelProducts?.value?.length > 1
			? `/new-buy-insurance?token=${token}${hostPrefix ? `&site=${hostPrefix}` : ''}`
			: `/new-buy-insurance/${channelProducts?.value[0]?.product?.name}?token=${token}${
					hostPrefix ? `&site=${hostPrefix}` : ''
				}`;
	return pathname;
};

// Should be used within buy insurance pages...
export const getNewBuyInsurancePath = (productInstanceId, channelProducts, token, hostPrefix) => {
	const selectedProduct = channelProducts?.value?.find(e => e.product?.instanceId === productInstanceId);
	const pathname = `/new-buy-insurance/${selectedProduct?.product?.name}?token=${token}${
		hostPrefix ? `&site=${hostPrefix}` : ''
	}`;
	return pathname;
};

// Should be used within buy insurance pages...
export const optionWiseGetNewBuyInsurancePath = (productInstanceId, channelProducts, optionName, token, hostPrefix) => {
	const selectedProduct = channelProducts?.value?.find(e => e.product?.instanceId === productInstanceId);
	const pathname = `/new-buy-insurance/${selectedProduct?.product?.name}/${optionName}?token=${token}${
		hostPrefix ? `&site=${hostPrefix}` : ''
	}`;
	return pathname;
};

// Flow Type 1 & 2 we get from channel setting.
// Flow Type 1 -> Product - Quick Quote - Product Options - Underwritting - Attachment - Declarations.
// Flow Type 2 -> Product - Quick Quote - Product Options - Attachment - Declarations.
export const FlowType = {
	flowType1: 'Flow Type 1',
	flowType2: 'Flow Type 2',
	flowType3: 'Flow Type 3',
	flowType4: 'Flow Type 4',
	flowTypeUWCReferredPolicy: 'Flow Type UWC Referred Policy',
	flowTypeUCReferredPolicy: 'Flow Type UC Referred Policy',
	flowTypeUWCEndorsement: 'Flow Type UWC Endorsement',
	flowTypeUCQuotePolicy: 'Flow Type UC Quote Policy'
};

export const getFlowSteps = (
	channelSettings,
	channelProducts,
	hasAttachments,
	hasDeclarations,
	draftPolicy = {}, // Optional
	resumePolicyPathNames // Optional
) => {
	const {
		formStepNameProductSelection: fsProductSelection,
		formStepNameQuote: fsQuote,
		formStepNameProductOptionSelection: fsProductOptionSelection,
		formStepNameUnderwriting: fsUnderwriting,
		formStepNameAttachments: fsAttachments,
		formStepNameDeclarations: fsDeclarations,
		formStepNameOverview: fsOverview,
		formStepNameSearchPolicy: fsSearchPolicy,
		flowType
	} = channelSettings;

	let steps = [];

	const productsCount = channelProducts?.length || 1;

	let _flowType;

	if (draftPolicy?.draftType === resumePolicyPathNames?.resumeUWCReferredPolicy) {
		_flowType = FlowType?.flowTypeUWCReferredPolicy;
	} else if (draftPolicy?.draftType === resumePolicyPathNames?.resumeUCReferredPolicy) {
		_flowType = FlowType?.flowTypeUCReferredPolicy;
	} else if (draftPolicy?.draftType === resumePolicyPathNames?.resumeUWCEndorsement) {
		_flowType = FlowType?.flowTypeUWCEndorsement;
	} else if (draftPolicy?.draftType === resumePolicyPathNames?.resumeUCQuotePolicy) {
		_flowType = FlowType?.flowTypeUCQuotePolicy;
	} else {
		_flowType = flowType;
	}

	// We can't remove fsQuote (Quick Quote) step as it is the one which is setting Factors & Risks default values.
	// WARNING - Removing fsQuote step will fail initializing the form.
	switch (_flowType) {
		case FlowType.flowType1:
			steps = [fsQuote, fsProductOptionSelection, fsUnderwriting];
			break;
		case FlowType.flowType2:
			steps = [fsQuote, fsProductOptionSelection];
			break;
		case FlowType.flowType3:
			steps = [fsQuote, fsProductOptionSelection, fsUnderwriting, fsOverview];
			break;
		case FlowType.flowType4:
			steps = [fsSearchPolicy, fsQuote, fsProductOptionSelection, fsUnderwriting];
			break;
		case FlowType.flowTypeUWCReferredPolicy:
			steps = [fsQuote, fsProductOptionSelection, fsUnderwriting];
			break;
		case FlowType.flowTypeUCReferredPolicy:
			steps = [fsQuote];
			break;
		case FlowType.flowTypeUCQuotePolicy:
			steps = [fsQuote, fsProductOptionSelection, fsUnderwriting];
			break;
		case FlowType.flowTypeUWCEndorsement:
			steps = [fsQuote, fsProductOptionSelection, fsUnderwriting];
			break;
		default:
			steps = [fsQuote, fsProductOptionSelection, fsUnderwriting];
	}

	if (
		productsCount > 1 &&
		draftPolicy?.draftType !== resumePolicyPathNames?.resumeUWCReferredPolicy &&
		draftPolicy?.draftType !== resumePolicyPathNames?.resumeUCReferredPolicy &&
		draftPolicy?.draftType !== resumePolicyPathNames?.resumeUCQuotePolicy &&
		draftPolicy?.draftType !== resumePolicyPathNames?.resumeUWCEndorsement
	) {
		steps.unshift(fsProductSelection);
	}

	if (
		hasAttachments &&
		draftPolicy?.draftType !== resumePolicyPathNames?.resumeUWCReferredPolicy &&
		draftPolicy?.draftType !== resumePolicyPathNames?.resumeUCReferredPolicy &&
		draftPolicy?.draftType !== resumePolicyPathNames?.resumeUCQuotePolicy &&
		draftPolicy?.draftType !== resumePolicyPathNames?.resumeUWCEndorsement
	) {
		steps.splice(steps.includes(fsProductSelection) ? 4 : 3, 0, fsAttachments);
	}

	if (hasDeclarations) {
		steps.splice(
			steps.includes(fsProductSelection)
				? steps.includes(fsAttachments)
					? 6
					: 5
				: steps.includes(fsAttachments)
					? 5
					: 4,
			0,
			fsDeclarations
		);
	}

	return steps;
};
