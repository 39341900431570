import { Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import * as context from 'api/base';
import { getChannelContentsByKey, getChannelSettingByKey } from '../../routes/generic/Helpers/GenericIndexHelper';
import { useStyles } from '../styles/FooterLayoutAdvancedStyles';

const FooterLayoutAdvanced = () => {
	const themeinfo = context.getSettings();
	const classes = useStyles(themeinfo);
	const footerSecondFirstCardContent = getChannelContentsByKey('Footer-Second-First-Card-Content');
	const footerSecondSecondCardContent = getChannelContentsByKey('Footer-Second-Second-Card-Content');
	const footerSecondThirdCardContent = getChannelContentsByKey('Footer-Second-Third-Card-Content');
	const footerThirdFirstCardContent = getChannelContentsByKey('Footer-Third-First-Card-Content');
	const footerThirdSecondCardContent = getChannelContentsByKey('Footer-Third-Second-Card-Content');
	const footerThirdThirdCardContent = getChannelContentsByKey('Footer-Third-Third-Card-Content');
	const hideSecondFooter = getChannelSettingByKey('Footer-Second-Hide')
		? getChannelSettingByKey('Footer-Second-Hide') === 'true'
		: true;
	const hideThirdFooter = getChannelSettingByKey('Footer-Third-Hide')
		? getChannelSettingByKey('Footer-Third-Hide') === 'true'
		: true;

	return (
		<div>
			{!hideSecondFooter && (
				<div style={classes?.secondFooterBgColor}>
					<div className="container">
						<Grid container spacing={4}>
							<Grid item xs={12} md={4}>
								<Typography paragraph={false} variant="body1">
									{footerSecondFirstCardContent && (
										<div
											dangerouslySetInnerHTML={{
												__html: footerSecondFirstCardContent
											}}
										/>
									)}
								</Typography>
							</Grid>
							<Grid item xs={12} md={4}>
								{footerSecondSecondCardContent && (
									<div
										dangerouslySetInnerHTML={{
											__html: footerSecondSecondCardContent
										}}
									/>
								)}
							</Grid>
							<Grid item xs={12} md={4}>
								{footerSecondThirdCardContent && (
									<div
										dangerouslySetInnerHTML={{
											__html: footerSecondThirdCardContent
										}}
									/>
								)}
							</Grid>
						</Grid>
					</div>
				</div>
			)}
			{!hideThirdFooter && (
				<div style={classes?.thirdFooterBgColor} className="mt-10">
					<div className="container">
						<Grid container spacing={4}>
							<Grid item xs={12} md={4}>
								{footerThirdFirstCardContent && (
									<div
										dangerouslySetInnerHTML={{
											__html: footerThirdFirstCardContent
										}}
									/>
								)}
							</Grid>
							<Grid item xs={12} md={4}>
								{footerThirdSecondCardContent && (
									<div
										dangerouslySetInnerHTML={{
											__html: footerThirdSecondCardContent
										}}
									/>
								)}
							</Grid>
							<Grid item xs={12} md={4}>
								{footerThirdThirdCardContent && (
									<div
										dangerouslySetInnerHTML={{
											__html: footerThirdThirdCardContent
										}}
									/>
								)}
							</Grid>
						</Grid>
					</div>
				</div>
			)}
		</div>
	);
};

export default FooterLayoutAdvanced;
