import Hidden from '@mui/material/Hidden';
import { memo } from 'react';
import TopbarLayout from './TopbarLayout';

function TopbarWrapperLayout(props) {
	return (
		<>
			<Hidden mdDown>
				<TopbarLayout />
			</Hidden>
		</>
	);
}

export default memo(TopbarWrapperLayout);
