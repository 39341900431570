import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { setMySite, setReferenceToken, setReloadcount } from 'app/store/briisk/formSlice';
import store from 'app/store';
import {
	getChannelProducts,
	getChannelSettings,
	getCompanyDetails,
	getRefererAgentData,
	getModifiedHost
} from 'app/store/briisk/actions/formAction';
import GoogleTags from 'app/layouts/whitelabel/routes/generic/Components/GoogleTags';
import LoadingError from 'app/layouts/whitelabel/routes/LoadingError';
import FuseSplashScreen from '@briisk/core/FuseSplashScreen';
import * as context from 'api/base';
import { setParam } from 'utils/locationHistory';

// eslint-disable-next-line react/prefer-stateless-function
const Auth = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const channelSettings = useSelector(({ fuse }) => fuse.form.channelSettings);
	const channelProducts = useSelector(({ fuse }) => fuse.form.channelProducts);
	const reloadCount = useSelector(({ fuse }) => fuse.form.reloadCount);
	const referenceTokenDetails = useSelector(({ fuse }) => fuse.form.referenceTokenDetails);
	const channelInstanceId = channelSettings?.value?.channel?.instanceId;

	// To set the Tabname & the Favicon
	useEffect(() => {
		const favicon = document.getElementById('favicon');
		// To set Tab Name
		const channelHost = context.getChannelHost();
		// To set Fav Icon
		if (channelHost?.favIconUrl) favicon.href = channelHost.favIconUrl;
	}, [channelSettings?.status]);

	// Whenever the Token changes, get the token details and the token's company details
	useEffect(() => {
		// Setting the selling token on the URL
		// WARNING - Changing the below lines position might break the funtionality
		setParam(navigate, referenceTokenDetails?.token);
		const queryParams = { ...queryString.parse(window.location.search) };
		if (referenceTokenDetails?.token && referenceTokenDetails?.token === queryParams?.token && channelInstanceId) {
			(async () => {
				// Getting agent details
				const response = await dispatch(
					getRefererAgentData({
						channelInstanceId,
						LoginType: 0,
						agentSellingCode: referenceTokenDetails?.token
					})
				);
				// As per the requirement, if the agent is not valid,
				// then we have to take them to the contact us page.
				if (!response?.payload) {
					navigate('/contact', {
						state: {
							isWhenAgentIsNotValid: true
						}
					});
					return;
				}
				// Getting agent's company details
				dispatch(getCompanyDetails(response?.payload?.companyInstanceId));
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [referenceTokenDetails?.token, channelInstanceId]);

	// Do not allow a user to change the token on onboarding page.
	// If they do, then redirect them back to the home page.
	const doesTheUserChangedTokenOnBoardingPage = backedUpToken => {
		if (window?.location?.href?.includes('buy-insurance')) {
			const queryParamToken = { ...queryString.parse(window.location.search) }?.token;
			if (backedUpToken && backedUpToken !== queryParamToken) {
				return navigate('/');
			}
			return undefined;
		}
		return undefined;
	};

	const getInitSettings = () => {
		// Setting the site prefix
		const hostPrefix = { ...queryString.parse(window.location.search) }?.site;
		const mySitePayload = { hostPrefix, host: getModifiedHost(hostPrefix) };
		dispatch(setMySite({ ...mySitePayload }));

		// Making the initial API calls
		const promise1 = dispatch(getChannelSettings({ _host: mySitePayload.host }));
		const promise2 = dispatch(getChannelProducts({ _host: mySitePayload.host }));
		Promise.all([promise1, promise2])
			.then(values => {
				if (values[0].error || values[1].error) {
					throw new Error();
				}

				// Setting the Selling Token...
				const backedUpToken = { ...referenceTokenDetails }?.token;
				const queryParams = { ...queryString.parse(window.location.search) };
				const tkn = queryParams?.token || referenceTokenDetails?.token || context.getChannelHost().defaultToken;

				dispatch(setReferenceToken({ token: tkn }));

				!tkn &&
					toast.error('No reference token found.', {
						autoClose: 4000
					});

				doesTheUserChangedTokenOnBoardingPage(backedUpToken);
			})
			.catch(e => {
				if (reloadCount >= 3) {
					return;
				}
				dispatch(setReloadcount(reloadCount + 1));
				window.location.reload(false);
			});
	};

	useEffect(() => {
		getInitSettings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isLoading = () => store && channelSettings?.status === 'success' && channelProducts?.status === 'success';

	return !isLoading() ? (
		reloadCount >= 3 ? (
			<LoadingError />
		) : (
			<FuseSplashScreen />
		)
	) : (
		<>
			{/* <-- START - POPULATING META-TAG, TITLE DYNAMICALLY --> */}
			<GoogleTags />
			{/* <-- END - POPULATING META-TAG, TITLE DYNAMICALLY --> */}

			{props.children}
		</>
	);
};

export default Auth;
