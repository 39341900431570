import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { getLogo } from '../helpers/Generichelper';
import { getChannelSettingByKey } from '../../routes/generic/Helpers/GenericIndexHelper';
import { useStylesFooterLogo } from '../styles/FooterStyles';

function FooterLogo() {
	const theme = useTheme();
	const secondaryLogoTextColor = getChannelSettingByKey('Footer-Logo-Text-Color');
	const classes = useStylesFooterLogo(theme, secondaryLogoTextColor);
	const agentDetails = useSelector(({ fuse }) => fuse?.form?.referenceTokenDetails?.agentDetails);
	const footerLogo =
		agentDetails?.agentLogoType === 2 && agentDetails?.agentLogoBlobUrl
			? agentDetails?.agentLogoBlobUrl
			: getLogo()?.secondaryLogo;
	const secondaryLogoText = getChannelSettingByKey('SECONDARY-LOGO-TEXT');

	return (
		<div style={classes.container}>
			<Link to="/" style={classes.linkContainer}>
				{footerLogo && <img style={classes.logo} src={footerLogo} alt="" />}
			</Link>
			{secondaryLogoText && <span style={classes.logoText}>{secondaryLogoText}</span>}
		</div>
	);
}

export default FooterLogo;
