export const CHECKBOX_LINK_TYPES = {
	DESCRIPTION: 'Description',
	HTML_TEXT: 'HTML Text',
	UNDERNEATH: 'Underneath'
};

export const ENDORSABLE_FLAG = {
	READ_ONLY: 1,
	AMENDABLE: 2,
	ENDORSABLE: 3
};

export const RISKS_TYPES = {
	INFORMATION_RISK: 0,
	COVER_RISKS: 1,
	BENEFIT_RISK: 2
};

export const SEARCH_POLICY_STEPS = {
	RISK_DETAILS: 0,
	OTP_VERIFICATION: 1,
	POLICY_DETAILS: 2
};

export const SELLING_TOKEN_REDIRECTION = {
	CONTINUE_WITH_DEFAULT_TOKEN: 0,
	AUTO_CONTINUE_WITH_DEFAULT_TOKEN: 1,
	DISABLE_DEFAULT_TOKEN_SALES: 2
};
