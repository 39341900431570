import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { parameters } from 'api/parameter';

export const { host } = window.location;
// export const host = 'dev.demoinsurance.com';

export const getModifiedHost = _hostPrefix => {
	if (
		host?.toLowerCase()?.includes('demoinsurance') &&
		(host?.toLowerCase()?.includes('dev') ||
			host?.toLowerCase()?.includes('uat') ||
			host?.toLowerCase()?.includes('prod'))
	) {
		if (
			host?.toLowerCase()?.startsWith('dev') ||
			host?.toLowerCase()?.startsWith('uat') ||
			host?.toLowerCase()?.startsWith('prod')
		) {
			return `${_hostPrefix}-${host}`;
		}
		return host;
	}
	return host;
};

axios.interceptors.request.use(config => {
	config.headers['Ocp-Apim-Subscription-Key'] = parameters.brandLabelSubscriptionKey;
	config.headers['My-Host'] = getModifiedHost(new URLSearchParams(window.location.search).get('site'));
	return config;
});

export const genericErrorMessage = 'Something is not correct. Please try again.';

export const getChannelSettings = createAsyncThunk('channelSettings', async ({ _host }) => {
	const response = await axios.get(`${parameters.serviceUrl}/Channel/Details/${_host}`);
	return response.data;
});

export const getChannelProducts = createAsyncThunk('channelproducts', async ({ _host }) => {
	const response = await axios.get(`${parameters.serviceUrl}/Channel/Products/${_host}`);
	return response.data;
});

export const getDeclaration = createAsyncThunk('getDeclaration', async ({ productId }) => {
	const response = await axios.get(`${parameters.serviceUrl}/GetProductDeclarations/${productId}`);
	return response.data.declarations;
});

export const getAttachments = createAsyncThunk('attachments', async ({ productId, category = 1 }) => {
	const response = await axios.get(`${parameters.serviceUrl}/GetProductAttachments/${productId}/${category}`);
	return { attachments: response.data };
});

export const createPolicySale = createAsyncThunk('createPolicySale', async ({ data, productId, optionId }) => {
	const response = await axios.post(
		`${parameters.serviceUrl}/Policy/CreatePolicySale/${productId}/${optionId}`,
		data
	);
	return JSON.parse(response.data);
});

export const quotePolicySale = createAsyncThunk('quotePolicySale', async ({ data, productId, optionId }) => {
	const response = await axios.post(
		`${parameters.serviceUrl}/Policy/CreatePolicySale/${productId}/${optionId}`,
		data
	);
	return JSON.parse(response.data);
});

export const addPolicySaleAttachmentsBase64 = createAsyncThunk('addPolicySaleAttachments', async data => {
	const response = await axios.post(`${parameters.serviceUrl}/AddPolicySaleAttachmentsBase64`, data);
	return JSON.parse(response.data);
});

export const rejectPolicy = createAsyncThunk('rejectPolicy', async ({ policyReference }) => {
	const response = await axios.post(`${parameters.serviceUrl}/Policy/RejectPolicy/${policyReference}`, {});
	return JSON.parse(response.data);
});

export const makePolicySale = createAsyncThunk('makePolicySale', async data => {
	const response = await axios.post(`${parameters.serviceUrl}/Policy/MakeSalePayment`, data);
	return JSON.parse(response.data);
});

export const issuePolicy = createAsyncThunk('issuePolicy', async ({ data, policySaleReference, policyReference }) => {
	const response = await axios.post(
		`${parameters.serviceUrl}/Product/Issue/${policySaleReference}/${policyReference}`,
		data
	);
	return JSON.parse(response.data);
});

export const sendOTP = createAsyncThunk('sendOTP', async ({ data }) => {
	const response = await axios.post(`${parameters.serviceUrl}/Policies/SendOtp`, data);
	return response.data;
});

export const validateOTP = createAsyncThunk('validateOTP', async ({ data }) => {
	const response = await axios.post(`${parameters.serviceUrl}/Policies/ValidateOtp`, data);
	return response.data;
});

export const getTypes = createAsyncThunk('types', async () => {
	const response = await axios.get(`${parameters.serviceUrl}/GetTypes`);
	return response.data;
});

export const getProductPriceNew = createAsyncThunk('productPriceNew', async request => {
	let response;

	if (!request?.isProductPaymentAvailable) {
		return toast.error('No payment providers configured');
	}

	if (request.calculatorVersion === 1) {
		response = await axios.post(
			`${parameters.serviceUrl}/ExecuteV1CalculatorDirect/${request?.collectionType}`,
			request
		);
	} else {
		response = await axios.post(`${parameters.serviceUrl}/ExecuteV2CalculatorDirect`, request);
	}

	return response.data;
});

export const getPaymentStatus = createAsyncThunk('paymentStatus', async ({ policyReference, policySaleReference }) => {
	const response = await axios.get(
		`${parameters.serviceUrl}/Payment/GetPaymentStatus/${policyReference}/${policySaleReference}`
	);
	return response.data;
});

export const approvePolicy = createAsyncThunk('approvePolicyResponse', async request => {
	const response = await axios.post(`${parameters.serviceUrl}/Policy/ApprovePolicy`, request);
	return response.data;
});

export const getRefererAgentData = createAsyncThunk('refererAgentData', async request => {
	const response = await axios.post(`${parameters.serviceUrl}/Policy/GetRefererAgentData`, request);
	return response.data;
});

export const getProductPayment = createAsyncThunk(
	'productPaymentResponse',
	async ({ productInstanceId, agentCompanyInstanceId }) => {
		const response = await axios.get(
			`${parameters.serviceUrl}/Product/GetProductPayment/${productInstanceId}/${agentCompanyInstanceId}`
		);
		return response.data;
	}
);

export const generateCheckout = createAsyncThunk('generateCheckoutResponse', async requestBody => {
	const response = await axios.post(`${parameters.serviceUrl}/Payment/GenerateCheckout`, requestBody);
	return response.data;
});

export const storeDraftPolicy = createAsyncThunk('draftPolicy', async payload => {
	const response = await axios.post(`${parameters.serviceUrl}/PolicyDraft/AddPolicyDraft`, payload);
	return response.data;
});

export const updateDraftPolicy = createAsyncThunk('updatingDraftPolicy', async payload => {
	const response = await axios.put(`${parameters.serviceUrl}/PolicyDraft/UpdatePolicyDraft`, payload);
	return response.data;
});

export const getDraftPolicy = createAsyncThunk('gettingDraftPolicy', async ({ instanceId, reference, _host }) => {
	const response = await axios.get(
		`${parameters.serviceUrl}/PolicyDraft/GetDraftedPolicy/${_host}/${instanceId}/${reference}`
	);
	return response.data;
});

export const getDraftDetailsFromPolicyVersion = createAsyncThunk(
	'getDraftDetailsFromPolicyVersion',
	async ({ policyNumber, policySaleInstanceId }) => {
		const response = await axios.get(
			`${parameters.serviceUrl}/Policies/GetDraftDetailsFromPolicyVersion/${policyNumber}/${policySaleInstanceId}`
		);
		return response.data;
	}
);

export const agentLogin = createAsyncThunk('agentLogin', async agentLoginRequest => {
	const response = await axios.post(`${parameters.serviceUrl}/Authorisation/AgentLogin`, agentLoginRequest);
	return response.data;
});

export const agentSendPasswordResetEmail = createAsyncThunk('agentSendPasswordResetEmail', async request => {
	const response = await axios.post(`${parameters.serviceUrl}/Authorisation/AgentSendPasswordResetEmail`, request);
	return response.data;
});

export const agentResetPassword = createAsyncThunk('agentResetPassword', async request => {
	const response = await axios.post(`${parameters.serviceUrl}/Authorisation/AgentResetPassword`, request);
	return response.data;
});

export const sendAgentOTP = createAsyncThunk('sendAgentOTP', async ({ key }) => {
	const response = await axios.post(`${parameters.serviceUrl}/Authorisation/AgentGenerateOtp/${key}`, {});
	return response.data;
});

export const validateAgentOTP = createAsyncThunk('validateAgentOTP', async ({ data }) => {
	const response = await axios.post(`${parameters.serviceUrl}/Authorisation/AgentValidateOtp`, data);
	return response.data;
});

export const searchPolicyForClaims = createAsyncThunk('searchPolicyForClaims', async ({ policyNumber }) => {
	const response = await axios.get(`${parameters.serviceUrl}/Claims/GetPolicyDetailsForClaims/${policyNumber}`);
	return response.data;
});

export const createClaims = createAsyncThunk('createClaims', async ({ data }) => {
	const response = await axios.post(`${parameters.serviceUrl}/Claims/CreateClaim`, data);
	return response.data;
});

export const addClaimsAttachments = createAsyncThunk(
	'addClaimsAttachments',
	async ({ claimInstanceId, claimReference, data }) => {
		const response = await axios.post(
			`${parameters.serviceUrl}/AddAttachmentsForClaims/${claimInstanceId}/${claimReference}`,
			data
		);
		return JSON.parse(response.data);
	}
);

export const approvePolicySale = createAsyncThunk('approvePolicySale', async ({ payload, jwtToken }) => {
	const response = await axios.post(`${parameters.serviceUrl}/Policy/ApprovePolicySale`, payload, {
		headers: {
			Authorization: `Bearer ${jwtToken}`
		}
	});
	return response.data;
});

export const rejectPolicySale = createAsyncThunk('rejectPolicySale', async ({ payload, jwtToken }) => {
	const response = await axios.post(`${parameters.serviceUrl}/Policy/RejectPolicySale`, payload, {
		headers: {
			Authorization: `Bearer ${jwtToken}`
		}
	});
	return response.data;
});

export const updatePolicySale = createAsyncThunk('updatePolicySale', async ({ payload, params }) => {
	const response = await axios.post(
		`${parameters.serviceUrl}/Policy/UpdatePolicySale?blFlowType=${params?.blFlowType}`,
		payload
	);
	return response.data;
});

export const getCompanyDetails = createAsyncThunk('getCompanyDetails', async companyInstanceInd => {
	const response = await axios.get(`${parameters.serviceUrl}/Organization/getCompanyDetails/${companyInstanceInd}`);
	return response.data;
});

export const searchLookups = createAsyncThunk('searchLookups', async data => {
	const response = await axios.post(
		`${parameters.serviceUrl}/GetNetworkLookupItems/${data.instanceId}`,
		data.payload
	);
	return response.data;
});

export const getPoliciesDetails = createAsyncThunk('getPoliciesDetails', async data => {
	const response = await axios.post(`${parameters.serviceUrl}/Policies/GetPoliciesDetails`, data.payload);
	return response.data;
});

export const addRiskAttributeAttachment = createAsyncThunk('addRiskAttributeAttachment', async data => {
	const response = await axios.post(`${parameters.serviceUrl}/Policy/AddRiskAttributeAttachment`, data);
	return response.data;
});

export const searchPolicyByIdentifiers = createAsyncThunk('searchPolicyByIdentifiers', async data => {
	try {
		const response = await axios.post(`${parameters.serviceUrl}/Policies/SearchCustomerRecordByIdentifiers`, data);
		return response.data;
	} catch (error) {
		toast.error(error?.response?.data?.error || genericErrorMessage, {
			autoClose: 4000
		});
		throw error;
	}
});

export const getImageBase64 = createAsyncThunk('getImageBase64', async data => {
	const response = await axios.post(`${parameters.serviceUrl}/getImageBase64`, data);
	return response.data;
});

export const endorseQuotePolicySale = createAsyncThunk('endorseQuotePolicySale', async ({ data }) => {
	const response = await axios.post(`${parameters.serviceUrl}/Policy/EndorseQuotePolicySale`, data);
	return response.data;
});

export const endorseIssuePolicySale = createAsyncThunk('endorseIssuePolicySale', async request => {
	const response = await axios.post(`${parameters.serviceUrl}/Policy/EndorseIssuePolicySale`, request);
	return response.data;
});

export const getEndorsementProductPriceNew = createAsyncThunk('getEndorsementProductPriceNew', async request => {
	if (!request?.isProductPaymentAvailable) {
		return toast.error('No payment providers configured');
	}
	const response = await axios.post(`${parameters.serviceUrl}/EndorsementExecuteCalculatorsV2`, request);
	return response.data;
});
