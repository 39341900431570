import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { setParam } from 'utils/locationHistory';

const LoadingError = () => {
	const navigate = useNavigate();
	const referenceTokenDetails = useSelector(({ fuse }) => fuse.form.referenceTokenDetails);

	// Setting up reference token...
	useEffect(() => {
		setParam(navigate, referenceTokenDetails?.token);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex justify-center items-center flex-col m-auto p-28 w-full bg-white h-screen">
			<div className="mb-10">
				<h2>Loading...</h2>
			</div>
			<LinearProgress className="w-192 sm:w-320 max-w-full rounded-2" color="primary" />
		</div>
	);
};

export default LoadingError;
