/* eslint-disable */
import Button from '@mui/material/Button';
import { Grid, styled, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { saveCookies } from 'app/store/briisk/formSlice';
import {
	accordionArrow,
	accordionDetailsStyle,
	accordionDividerStyle,
	accordionStyle,
	accordionSummaryStyle,
	antSwitchStyle,
	switchStyle,
	tabCellStyle,
	tabRowStyle,
	useStyles
} from '../styles/CookiesStyle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import useWindowDimensions from 'utils/useWindowDimensions';
import { getChannelSettingByKey } from '../../routes/generic/Helpers/GenericIndexHelper';

const AntSwitch = styled(Switch)(({ theme }) => antSwitchStyle(theme));

const StyledTableCell = styled(TableCell)(({ theme }) => tabCellStyle(theme));

const StyledTableRow = styled(TableRow)(() => tabRowStyle);

function createData(cookie, duration, desc) {
	return { cookie, duration, desc };
}

const rows = [
	createData(
		'cookielawinfo-checkbox-analytics',
		'11 months',
		'This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Analytics".'
	),
	createData(
		'cookielawinfo-checkbox-functional',
		'11 months',
		'The cookie is set by GDPR cookie consent to record the user consent for the cookies in the category "Functional".'
	),
	createData(
		'cookielawinfo-checkbox-necessary	',
		'11 months',
		'This cookie is set by GDPR Cookie Consent plugin. The cookies is used to store the user consent for the cookies in the category "Necessary".'
	),
	createData(
		'cookielawinfo-checkbox-others',
		'11 months',
		'This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Other.'
	),
	createData(
		'cookielawinfo-checkbox-performance',
		'11 months',
		'This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Performance".'
	),
	createData(
		'viewed_cookie_policy	',
		'11 months',
		'The cookie is set by the GDPR Cookie Consent plugin and is used to store whether or not user has consented to the use of cookies. It does not store any personal data.'
	)
];

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(
	() => accordionStyle
);

const AccordionSummary = styled(props => (
	<MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={accordionArrow} />} {...props} />
))(() => accordionSummaryStyle);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => accordionDetailsStyle(theme));

const AccordionDivider = styled('div')(() => accordionDividerStyle);

function Cookies(props) {
	const theme = useTheme();
	const classes = useStyles(theme);
	const dispatch = useDispatch();
	const isEnabled = useSelector(({ fuse }) => fuse.form?.cookies?.enabled);
	const text = `This website uses cookies to improve your experience while you navigate through the website. Out of these, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may affect your browsing experience.`;
	const [openSettings, setOpenSettings] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const [expanded, setExpanded] = useState('panel0');
	const [onNecessary, setOnNecessary] = useState(true);
	const [onFunctional, setOnFunctional] = useState(false);
	const [onPerformance, setOnPerformance] = useState(false);
	const [onAnalytics, setOnAnalytics] = useState(false);
	const [onAdvertisement, setOnAdvertisement] = useState(false);
	const [onOthers, setOnOthers] = useState(false);
	const { width } = useWindowDimensions();
	const displayCookieSetting = getChannelSettingByKey('Display-Cookie-Setting') === 'true';

	// Get the current date
	const currentDate = new Date();

	// Calculate the expiry date
	const expiryDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());

	// Format the expiry date as a string in the correct format for cookies
	const expiryDateString = expiryDate.toUTCString();

	// Applying "YES to all cookie categories.
	const handleAcceptCookies = () => {
		document.cookie = `functional=yes; expires=${expiryDateString};`;
		document.cookie = `performance=yes; expires=${expiryDateString};`;
		document.cookie = `analytics=yes; expires=${expiryDateString};`;
		document.cookie = `advertisement=yes; expires=${expiryDateString};`;
		document.cookie = `others=yes; expires=${expiryDateString};`;
		dispatch(
			saveCookies({
				enabled: true
			})
		);
	};

	// Applying "NO to all cookie categories.
	const handleDeclineCookies = () => {
		document.cookie = `functional=no; expires=${expiryDateString};`;
		document.cookie = `performance=no; expires=${expiryDateString};`;
		document.cookie = `analytics=no; expires=${expiryDateString};`;
		document.cookie = `advertisement=no; expires=${expiryDateString};`;
		document.cookie = `others=no; expires=${expiryDateString};`;
		dispatch(
			saveCookies({
				enabled: true
			})
		);
	};

	const toggleShowMore = () => {
		setShowMore(!showMore);
	};

	const handleChange = panel => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const handleSettingsOpen = () => {
		setOpenSettings(true);
	};

	const handleSettingsClose = () => {
		setOpenSettings(false);
	};

	const handleCookieChange = (name, event) => {
		switch (name) {
			case 'functional':
				setOnFunctional(event.target.checked);
				return (document.cookie = `functional=${
					event.target.checked ? 'true' : 'false'
				}; expires=${expiryDateString};`);

			case 'performance':
				setOnPerformance(event.target.checked);
				return (document.cookie = `performance=${
					event.target.checked ? 'true' : 'false'
				}; expires=${expiryDateString};`);
			case 'analytics':
				setOnAnalytics(event.target.checked);
				return (document.cookie = `analytics=${
					event.target.checked ? 'true' : 'false'
				}; expires=${expiryDateString};`);
			case 'advertisement':
				setOnAdvertisement(event.target.checked);
				return (document.cookie = `advertisement=${
					event.target.checked ? 'true' : 'false'
				}; expires=${expiryDateString};`);
			case 'others':
				setOnOthers(event.target.checked);
				return (document.cookie = `others=${
					event.target.checked ? 'true' : 'false'
				}; expires=${expiryDateString};`);
		}
	};

	const handleSave = () => {
		setOpenSettings(false);
		dispatch(
			saveCookies({
				enabled: true
			})
		);
	};

	if (isEnabled) return <div />;

	return (
		<div className="w-full">
			<div className="">
				<div style={classes.container}>
					<Grid container direction="row" alignItems="center" justifyContent="space-between">
						<Grid item md={displayCookieSetting ? 7 : 8} xs={12}>
							<p style={classes.title}>This website uses cookies</p>
							<p style={classes.content}>
								We use necessary cookies to improve your website experience and analyse the site use.
								For more detailed information about the cookies we use, see our <a>Cookie Policy</a>.
							</p>
						</Grid>
						<Grid item md={displayCookieSetting ? 5 : 4} xs={12}>
							<div style={classes.btnContainer}>
								{displayCookieSetting && (
									<Button
										sx={classes.cardButton}
										variant="contained"
										color="primary"
										onClick={handleSettingsOpen}
									>
										Cookie Settings
									</Button>
								)}
								<Button
									sx={classes.cardButton}
									variant="contained"
									color="primary"
									onClick={handleDeclineCookies}
								>
									Decline All Cookies
								</Button>
								<Button
									sx={classes.acceptButton}
									variant="contained"
									color="primary"
									onClick={handleAcceptCookies}
								>
									Accept All Cookies
								</Button>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>

			<Dialog open={openSettings} onClose={handleSettingsClose} style={{ zIndex: 9999 }}>
				<Paper sx={classes.dialogContainer}>
					<div style={classes.dialogTitle}>
						<Typography variant="h4">Privacy Overview</Typography>
					</div>
					<DialogContent>
						<Typography variant="h6">
							<p>{showMore ? text : `${text.slice(0, 250)}...`}</p>
							<br />
							<a onClick={toggleShowMore} style={classes.showMoreHyper}>
								{showMore ? 'Show Less' : 'Show More'}
							</a>
						</Typography>
						<br />
						<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
							<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
								<div style={classes.summaryContainer}>
									<Typography variant="h6">Necessary</Typography>
									<Typography variant="h6">
										{onNecessary === true ? 'Always Enabled' : 'Disabled'}
									</Typography>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<Typography variant="h6" sx={classes.contentText}>
									Necessary cookies are absolutely essential for the website to function properly.
									These cookies ensure basic functionalities and security features of the website,
									anonymously.
								</Typography>
								<br />
								<Typography>
									<TableContainer component={Paper}>
										<Table aria-label="customized table">
											<TableHead>
												<TableRow style={{ backgroundColor: '#F0F2F2' }}>
													<StyledTableCell>Cookie</StyledTableCell>
													<StyledTableCell align="left">Duration</StyledTableCell>
													<StyledTableCell align="left">Description&nbsp;(g)</StyledTableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{rows.map(row => (
													<StyledTableRow key={row.cookie}>
														<StyledTableCell component="th" scope="row" width="30%">
															{row.cookie}
														</StyledTableCell>
														<StyledTableCell align="left" width="20%">
															{row.duration}
														</StyledTableCell>
														<StyledTableCell align="left" width="40%">
															{row.desc}
														</StyledTableCell>
													</StyledTableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Typography>
							</AccordionDetails>
						</Accordion>

						<AccordionDivider />

						<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
							<AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
								<div style={classes.summaryContainer}>
									<Typography variant="h6">Functional</Typography>
									<Typography variant="h6">
										<FormControlLabel
											control={
												<AntSwitch
													checked={onFunctional}
													onChange={event => handleCookieChange('functional', event)}
													sx={switchStyle(onFunctional)}
												/>
											}
											componentsProps={{ typography: { variant: 'h6' } }}
											label={onFunctional ? 'Enabled ' : 'Disabled'}
											labelPlacement="start"
										/>
									</Typography>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<Typography variant="h6" sx={classes.contentText}>
									Functional cookies help to perform certain functionalities like sharing the content
									of the website on social media platforms, collect feedbacks, and other third-party
									features.
								</Typography>
							</AccordionDetails>
						</Accordion>

						<AccordionDivider />

						<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
							<AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
								<div style={classes.summaryContainer}>
									<Typography variant="h6">Performance</Typography>
									<Typography variant="h6">
										<FormControlLabel
											control={
												<AntSwitch
													checked={onPerformance}
													onChange={event => handleCookieChange('performance', event)}
													sx={switchStyle(onPerformance)}
												/>
											}
											label={onPerformance ? 'Enabled ' : 'Disabled'}
											labelPlacement="start"
											componentsProps={{ typography: { variant: 'h6' } }}
										/>
									</Typography>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<Typography variant="h6" sx={classes.contentText}>
									Performance cookies are used to understand and analyze the key performance indexes
									of the website which helps in delivering a better user experience for the visitors.
								</Typography>
							</AccordionDetails>
						</Accordion>

						<AccordionDivider />

						<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
							<AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
								<div style={classes.summaryContainer}>
									<Typography variant="h6" sx={classes.heading_functional}>
										Analytics
									</Typography>
									<Typography
										variant="h6"
										sx={width > 590 ? classes.subtitle_analy : classes.subtitle_mobile_analy}
									>
										<FormControlLabel
											control={
												<AntSwitch
													checked={onAnalytics}
													onChange={event => handleCookieChange('analytics', event)}
													sx={switchStyle(onAnalytics)}
												/>
											}
											label={onAnalytics ? 'Enabled ' : 'Disabled'}
											labelPlacement="start"
											componentsProps={{ typography: { variant: 'h6' } }}
										/>
									</Typography>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<Typography variant="h6" sx={classes.contentText}>
									Analytical cookies are used to understand how visitors interact with the website.
									These cookies help provide information on metrics the number of visitors, bounce
									rate, traffic source, etc.
								</Typography>
							</AccordionDetails>
						</Accordion>

						<AccordionDivider />

						<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
							<AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
								<div style={classes.summaryContainer}>
									<Typography variant="h6">Advertisement</Typography>
									<Typography variant="h6">
										<FormControlLabel
											control={
												<AntSwitch
													checked={onAdvertisement}
													onChange={event => handleCookieChange('advertisement', event)}
													sx={switchStyle(onAdvertisement)}
												/>
											}
											label={onAdvertisement ? 'Enabled ' : 'Disabled'}
											labelPlacement="start"
											componentsProps={{ typography: { variant: 'h6' } }}
										/>
									</Typography>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<Typography variant="h6" sx={classes.contentText}>
									Advertisement cookies are used to provide visitors with relevant ads and marketing
									campaigns. These cookies track visitors across websites and collect information to
									provide customized ads.
								</Typography>
							</AccordionDetails>
						</Accordion>

						<AccordionDivider />

						<Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
							<AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
								<div style={classes.summaryContainer}>
									<Typography variant="h6">Others</Typography>
									<Typography variant="h6">
										<FormControlLabel
											control={
												<AntSwitch
													checked={onOthers}
													onChange={event => handleCookieChange('others', event)}
													sx={switchStyle(onOthers)}
												/>
											}
											label={onOthers ? 'Enabled ' : 'Disabled'}
											labelPlacement="start"
											componentsProps={{ typography: { variant: 'h6' } }}
										/>
									</Typography>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<Typography variant="h6" sx={classes.contentText}>
									Other uncategorized cookies are those that are being analyzed and have not been
									classified into a category as yet.
								</Typography>
							</AccordionDetails>
						</Accordion>
					</DialogContent>
					<DialogActions>
						<button style={classes.save} onClick={handleSave}>
							SAVE & ACCEPT
						</button>
					</DialogActions>
				</Paper>
			</Dialog>
		</div>
	);
}

export default Cookies;
