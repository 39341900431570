import React from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { clearLogging } from '../../../../../store/briisk/formSlice';

export const LoginButton = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const agentLoginResponse = useSelector(({ fuse }) => fuse.form.agentLoginResponse);

	const handleLogging = () => {
		if (
			agentLoginResponse?.status === 'success' &&
			agentLoginResponse?.value?.agentSellingCode &&
			agentLoginResponse?.value?.jwtToken
		) {
			if (window?.location?.href?.includes('buy-insurance')) {
				// eslint-disable-next-line no-restricted-globals, no-alert
				if (confirm('Changes you made may not be saved.')) {
					dispatch(clearLogging());
					navigate('/');
				} else {
					// Do nothing!
				}
			} else {
				dispatch(clearLogging());
			}
		} else {
			navigate('/agent/login');
		}
	};

	return (
		<div>
			<Button variant="contained" color="primary" sx={props.className} onClick={handleLogging}>
				{agentLoginResponse?.status === 'success' &&
				agentLoginResponse?.value?.agentSellingCode &&
				agentLoginResponse?.value?.jwtToken
					? 'Logout'
					: 'Login'}
			</Button>
		</div>
	);
};
