import { createTheme as createMuiTheme } from '@mui/material';
import { createUnarySpacing } from '@mui/system';
import * as context from 'api/base';

const createTheme = () => {
	/**
	 * Represents the spacing value without the "px" unit.
	 * @typedef {number} spacingWithoutPX
	 */

	/**
	 * NOTE: Use this spacing only for calculating the spacing value, not for applying it.
	 * Creates a unary spacing value with the specified spacing.
	 * @param {Object} options - The options for creating the unary spacing.
	 * @param {number} options.spacing - The spacing value.
	 * @returns {spacingWithoutPX} The spacing value without the "px" unit.
	 */
	const spacingWithoutPX = createUnarySpacing({
		spacing: 8
	});

	const info = context.getSettings();
	const getTrimmedColor = color => {
		if (color?.length) return color;
		return '#ffffff';
	};
	return createMuiTheme({
		typography: {
			fontFamily: ['Rubik', 'sans-serif'].join(','),
			fontWeightLight: 300,
			fontWeightRegular: 400,
			fontWeightMedium: 500,
			useNextVariants: true,
			suppressDeprecationWarnings: true,
			htmlFontSize: 10,
			fontSize: 13,
			body1: {
				fontSize: '1.3rem'
			},
			body2: {
				fontSize: '1.3rem'
			}
		},
		spacingV2: spacingWithoutPX,
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						textTransform: 'none',
						borderRadius: '18px'
					},
					sizeSmall: {
						borderRadius: '15px'
					},
					sizeLarge: {
						borderRadius: '21px'
					},
					contained: {
						boxShadow: 'none',
						'&:hover, &:focus': {
							boxShadow: 'none',
							backgroundColor: `${info.mainSecondaryColor} !important`
						}
					}
				}
			},
			MuiTab: {
				styleOverrides: {
					root: {
						textTransform: 'none'
					}
				}
			},
			MuiDialog: {
				styleOverrides: {
					paper: {
						borderRadius: 16
					}
				}
			},
			MuiPaper: {
				styleOverrides: {
					rounded: {
						borderRadius: 16
					}
				}
			},
			MuiPopover: {
				styleOverrides: {
					paper: {
						borderRadius: 8
					}
				}
			},
			MuiFilledInput: {
				styleOverrides: {
					root: {
						borderRadius: 4,
						'&:before, &:after': {
							display: 'none'
						}
					}
				}
			},
			MuiListItem: {
				styleOverrides: {
					root: {
						width: 'auto !important',
						textDecoration: 'none !important'
					}
				}
			},
			MuiStepper: {
				styleOverrides: {
					root: {
						padding: '24px !important'
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						'&.Mui-disabled': {
							backgroundColor: '#F0F0F0 !important'
						}
					}
				}
			}
		},
		palette: {
			mode: 'light',
			text: {
				primary: '#172417',
				secondary: '#6b7280',
				disabled: '#959ca9'
			},
			common: {
				black: '#111827',
				white: '#ffffff'
			},
			products: {
				cards: {
					background: getTrimmedColor(info.productCardBackgroundColor)
				},
				headerFontColor: getTrimmedColor(info.productCardHeaderFontColor)
			},
			hero: {
				background: '#F9F9F9',
				fontColor: getTrimmedColor(info.mainPrimaryColor)
			},
			primary: {
				light: '#fdf3da',
				main: getTrimmedColor(info.mainPrimaryColor),
				dark: '#f3bc53',
				contrastText: '#FFFFFF'
			},
			secondary: {
				light: '#5a737a',
				main: getTrimmedColor(info.mainSecondaryColor),
				dark: '#5a737a'
			},
			background: {
				paper: '#FFFFFF',
				default: getTrimmedColor(info.mainBackgroundColor)
			},
			header: {
				background: getTrimmedColor(info.headerBackground)
			},
			footer: {
				background: getTrimmedColor(info.footerBackgroundColor),
				fontColor: getTrimmedColor(info.footerTextColor),
				headerFontColor: getTrimmedColor(info.footerHeaderColor)
			}
			// error: 'red'
		}
	});
};

export default createTheme;
