import { StyleSheet, Font } from '@react-pdf/renderer';

// Register the Noto Sans font
Font.register({
	family: 'Noto Sans',
	fonts: [
		{ src: '/assets/fonts/NotoSans-Regular.ttf' },
		{ src: '/assets/fonts/NotoSans-Bold.ttf', fontWeight: 'bold' }
	]
});
export const useStylesBackToHome = themeinfo => {
	return {
		backToHomeLink: {
			textDecoration: 'none !important'
		},
		backToHomeBtn: {
			backgroundColor: themeinfo.mainPrimaryColor,
			color: 'white',
			boxShadow: '0 4px 8px rgb(0 0 0 / 10%)',
			borderRadius: '5px'
		}
	};
};

export const useStylesExecuteReferredSummaryPolicy = (theme, themeinfo) => {
	return {
		approveBtn: {
			color: 'white',
			backgroundColor: `${themeinfo.mainPrimaryColor} !important`,
			borderRadius: '5px',
			'&:hover': {
				backgroundColor: `${themeinfo.mainPrimaryColor} !important`
			}
		},
		rejectBtn: {
			color: '#000000',
			backgroundColor: '#e0e0e0 !important',
			borderRadius: '5px',
			'&:hover': {
				backgroundColor: '#e0e0e0 !important'
			}
		},
		modalContainer: {
			width: 500,
			padding: '0px 24px 10px 24px !important',
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		},
		modalButtons: {
			color: 'white !important',
			backgroundColor: `${themeinfo.mainPrimaryColor} !important`,
			borderRadius: '5px',
			marginLeft: '10px',
			padding: '6px 17px',
			'&:hover': {
				backgroundColor: `${themeinfo.mainPrimaryColor} !important`
			}
		},
		modalButtonDisabled: {
			opacity: 0.6
		},
		modalCancelBtn: {
			color: '#000000',
			backgroundColor: '#e0e0e0 !important',
			borderRadius: '5px',
			padding: '6px 17px',
			'&:hover': {
				backgroundColor: '#e0e0e0 !important'
			}
		},
		submitBtnContainer: {
			minWidth: 80,
			display: 'flex',
			justifyContent: 'center'
		},
		errorMessage: {
			color: 'red',
			textAlign: 'center',
			fontSize: '12px'
		}
	};
};

export const useStylesPolicySummary = () => {
	return {
		attributeHighlight: {
			color: '#ca004b !important'
		},
		divider: {
			width: '95%',
			margin: 0,
			borderWidth: 0,
			borderStyle: 'solid',
			borderColor: ' rgba(0, 0, 0, 0.06)',
			borderBottomWidth: 'thin'
		}
	};
};

export const useStylesUploadExcelFile = themeinfo => {
	return {
		container: {
			display: 'flex',
			justifyContent: 'center'
		},
		input: {
			display: 'none'
		},
		uploadButton: {
			backgroundColor: themeinfo.mainPrimaryColor,
			color: 'white !important',
			borderRadius: '8px',
			border: 'none',
			marginLeft: '10px !important',
			height: '33px'
		}
	};
};

export const useStylesRemoveRisk = themeinfo => {
	return {
		button: {
			backgroundColor: themeinfo.mainPrimaryColor,
			color: 'white !important',
			borderRadius: '8px',
			border: 'none',
			height: '33px',
			marginLeft: '10px !important'
		}
	};
};

export const useStylesDraftPolicy = () => {
	return {
		button: {
			color: 'white !important',
			borderRadius: '5px',
			border: 'none',
			height: '35px',
			marginLeft: '10px'
		}
	};
};

export const useStylesDownloadTemplate = () => {
	return {
		container: {
			marginTop: 10
		},
		button: {
			textDecoration: 'underline'
		}
	};
};

export const useStylesContacts = theme => {
	return {
		container: {
			[theme.breakpoints.down('xs')]: {
				marginLeft: '2rem !important',
				marginRight: '2rem !important'
			}
		},
		tabItem: {
			backgroundColor: theme.palette.background.default ?? '#ffffff'
		}
	};
};

export const useStylesPremiumBreakup = () => {
	return {
		breakUpGridSecondColumn: {
			textAlign: 'right'
		},
		breakUpGridFirstColumn: {
			textAlign: 'left'
		}
	};
};

export const useStylesDeclarationRecaptcha = () => {
	return {
		recaptchaContainer: {
			display: 'flex',
			justifyContent: 'center'
		}
	};
};

export const useStylesCollectionType = themeinfo => {
	return {
		frequencyText: {
			fontSize: 15,
			fontWeight: '400'
		},
		errorMessage: {
			color: `${themeinfo.mainFontColor} !important`,
			textAlign: 'left',
			fontSize: '12px'
		},
		gridContainer: {
			marginTop: '10px',
			marginLeft: '18px'
		}
	};
};

export const useStyles = theme => {
	return {
		'@global': {
			'code:not([class*="language-"])': {
				color: theme.palette.secondary.dark,
				backgroundColor: theme.palette.type === 'light' ? 'rgba(255, 255, 255, .9)' : 'rgba(0, 0, 0, .9)',
				padding: '2px 3px',
				borderRadius: 2,
				lineHeight: 1.7
			},
			'table.simple tbody tr td': {
				borderColor: theme.palette.divider
			},
			'table.simple thead tr th': {
				borderColor: theme.palette.divider
			},
			'a:not([role=button])': {
				color: theme.palette.secondary.main,
				textDecoration: 'none',
				'&:hover': {
					textDecoration: 'underline'
				}
			},
			'a.link, a:not([role=button])[target=_blank]': {
				color: 'inherit',
				borderBottom: `1px solid ${theme.palette.divider}`,
				textDecoration: 'none',
				'&:hover': {
					textDecoration: 'none'
				}
			},
			'[class^="border-"]': {
				borderColor: theme.palette.divider
			},
			'[class*="border-"]': {
				borderColor: theme.palette.divider
			},
			hr: {
				borderColor: theme.palette.divider
			},
			'::-webkit-scrollbar-thumb': {
				boxShadow: `inset 0 0 0 20px ${
					theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
				}`
			},
			'::-webkit-scrollbar-thumb:active': {
				boxShadow: `inset 0 0 0 20px ${
					theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
				}`
			},
			html: {
				backgroundColor: `${theme.palette.background.default}!important`,
				color: `${theme.palette.text.primary}!important`
			}
		},
		root: {
			backgroundColor: theme.palette.background.default,
			color: theme.palette.text.primary
		}
	};
};

export const useStylesWhiteLabel = () => {
	return {
		root: {
			'&.boxed': {
				clipPath: 'inset(0)',
				maxWidth: '100%',
				margin: '0 auto',
				boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
			},
			'&.container': {
				'& .container': {
					maxWidth: '100%',
					width: '100%',
					margin: '0 auto'
				}
			}
		}
	};
};

export const useStylesProduct = (theme, themeinfo) => {
	return {
		header: {
			background: theme.palette.primary.main,
			color: theme.palette.primary.contrastText
		},
		panel: {
			margin: 0,
			border: 'none',
			'&:before': {
				display: 'none'
			},
			'&:first-child': {
				borderRadius: '20px 20px 0 0'
			},
			'&:last-child': {
				borderRadius: '0 0 20px 20px'
			},
			'&$expanded': {
				margin: 'auto'
			}
		},
		card: {
			background: themeinfo.productCardBackgroundColor,
			color: '#707070'
		},
		cardHeader: {
			background: themeinfo.productCardHeaderColor,
			color: themeinfo.productCardHeaderFontColor
		},
		cardButton: {
			color: 'white !important'
		}
	};
};

export const useStylesProductOption = themeinfo => {
	return {
		card: {
			background: themeinfo.productCardBackgroundColor,
			color: themeinfo.productCardHeaderFontColor
		},
		cardHeader: {
			color: themeinfo.productCardHeaderFontColor
		},
		cardIcon: {
			textAlign: 'center',
			border: `2px solid ${themeinfo.productCardHeaderFontColor}`,
			borderRadius: '2px',
			backgroundColor: themeinfo.productCardBackgroundColor,
			verticalAlign: 'middle',
			marginRight: '15px'
		},
		cardIconImage: {
			height: '80%',
			margin: '10%'
		},
		cardFooter: {
			justifyContent: 'space-between'
		},
		cardButtonMain: {
			paddingRight: '32px',
			paddingLeft: '32px'
		},
		cardButton: {
			borderRadius: '5px',
			color: 'white !important'
		}
	};
};

export const useStylesCheckout = () => {
	return {
		loadeContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '80px',
			flexDirection: 'column'
		},
		retryGrid: {
			textAlign: 'center',
			marginTop: '40px'
		}
	};
};

export const useStylesFullProductOptions = themeinfo => {
	return {
		cardIcon: {
			textAlign: 'center',
			border: `2px solid ${themeinfo.productCardHeaderFontColor}`,
			borderRadius: '2px',
			backgroundColor: themeinfo.productCardBackgroundColor,
			verticalAlign: 'middle',
			marginRight: '15px'
		},
		cardIconImage: {
			height: '80%',
			margin: '10%'
		},
		cardButton: {
			borderRadius: '5px',
			color: 'white !important'
		},
		cardHeader: {
			color: themeinfo.productCardHeaderFontColor
		}
	};
};

export const useStylesPaymentSummaryPDF = () => {
	return {
		exportButton: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: 'auto'
		},
		exportBtn: {
			margin: '20px 0px',
			height: '40px',
			borderRadius: 2,
			fontSize: 14,
			fontWeight: '600'
		}
	};
};

export const pdfStyles = StyleSheet.create({
	page: {
		flexDirection: 'column'
	},
	section: {
		marginBottom: 10,
		padding: 20
	},
	table: {
		display: 'table',
		width: 'auto'
	},
	tableRow: {
		margin: 'auto',
		flexDirection: 'row'
	},
	tableCellHeader: {
		margin: 5,
		fontWeight: 'bold',
		borderStyle: 'solid',
		borderBottomWidth: 1
	},
	tableCell: {
		margin: 5,
		borderStyle: 'solid',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0
	},
	pdfImageContainer: {
		idth: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	img: {
		width: '150px',
		height: 'auto'
	},
	line: {
		width: '100%',
		border: '0.5px solid #E9E9E9',
		marginBottom: 1
	},
	divider: {
		width: '100%',
		marginBottom: 5
	},
	document: {
		width: '100%',
		height: '100%'
	},
	attributeSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginTop: 4
	},
	attributeText: {
		fontSize: 12,
		fontFamily: 'Noto Sans'
	},
	attributeTextLeft: {
		fontSize: 12,
		fontFamily: 'Noto Sans',
		marginBottom: 1,
		width: '50%'
	},
	attributeTextRight: {
		fontSize: 12,
		fontFamily: 'Noto Sans',
		marginBottom: 1,
		textAlign: 'left',
		width: '50%'
	},
	companyName: {
		fontWeight: 'bold',
		fontFamily: 'Noto Sans',
		marginBottom: '3px',
		fontSize: 16
	},
	policyReference: {
		fontWeight: 'bold',
		fontFamily: 'Noto Sans',
		marginBottom: '-10px',
		fontSize: 14
	},
	riskName: {
		fontSize: 12,
		fontWeight: 'bold',
		fontFamily: 'Noto Sans',
		marginTop: '9px'
	},
	image: {
		width: 150,
		height: 'auto'
	},
	headerSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		paddingLeft: 10,
		paddingRight: 10,
		height: 50
	}
});

export const useStylesSearchPolicy = (theme, channelSettings) => {
	return {
		description: {
			marginBottom: theme.spacing(2),
			fontWeight: 400
		},
		infoText: {
			marginBottom: theme.spacing(2),
			color: '#746a6a',
			fontSize: '11px'
		},
		disabledText: {
			color: '#afafaf'
		},
		selectOption: {
			width: '100%'
		},
		riskTitle: {
			backgroundColor: channelSettings.riskHeader2BackgroundColour,
			color: channelSettings.riskHeader2FontColor
		},
		loadingContainer: {
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'column',
			width: '100%',
			height: '70px',
			marginTop: 20,
			paddingTop: '50px',
			paddingBottom: '50px',
			alignItems: 'center'
		},
		card: {
			backgroundColor: channelSettings.cardBackgroundColour,
			color: channelSettings.cardFontColor,
			width: '100%',
			height: '100%',
			padding: '0px',
			cursor: 'pointer',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			alignItems: 'flex-start'
		},
		radioButton: {
			marginTop: 1,
			marginLeft: 0,
			padding: 0
		},
		cardSection: {
			paddingLeft: 5,
			width: '100%'
		},
		buttonLoader: {
			display: 'flex',
			alignItems: 'center',
			width: 80,
			justifyContent: 'center'
		}
	};
};
