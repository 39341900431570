export const useStylesFooterLayout = width => {
	return {
		Phone: {
			pointerEvents: width > 600 ? 'none' : 'auto'
		},
		recaptchaText: {
			textAlign: 'center',
			padding: '10px',
			zIndex: 1
		}
	};
};

export const useStylesFooterLogo = (theme, secondaryLogoTextColor) => {
	return {
		logo: {
			height: 'auto',
			width: 'auto',
			maxHeight: '100px',
			maxWidth: '120px',
			display: 'flex',
			alignSelf: 'center'
		},
		logoText: {
			fontSize: '10px',
			color: secondaryLogoTextColor ?? theme.palette.footer.fontColor,
			marginTop: '5px',
			cursor: 'default !important',
			textAlign: 'center',
			pointerEvents: 'none'
		},
		linkContainer: {
			display: 'flex',
			justifyContent: 'center'
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			textDecoration: 'none !important',
			paddingRight: '5px',
			[theme.breakpoints.down('xs')]: {
				alignItems: 'center',
				padding: '0 20px',
				textAlign: 'center'
			},
			'&:hover': {
				color: theme.palette.text.primary
			}
		}
	};
};
