import store from 'app/store';

export const getOnBoardingButtonName = ({ formName, buttonName }) => {
	return `${window?.location?.host}-${formName}-form-${buttonName}-btn`?.toLowerCase()?.replace(/ /g, '-');
};

export const getPagesButtonName = ({ pageName, buttonName }) => {
	return `${window?.location?.host}-${pageName}-pg-${buttonName}-btn`?.toLowerCase()?.replace(/ /g, '-');
};

// This function takes a numerical value and formats it according to the configured settings.
export const formatRatingTotal = (value, getChannelSettingByKey) => {
	// If it's not a number, return it as is.
	if (typeof value !== 'number') {
		return value;
	}

	const currencySymbol = getChannelSettingByKey('Country-Currency-Symbol') ?? 'ZAR';
	const separator = getChannelSettingByKey('Value-Separator');

	if (separator === 'Space' || separator === 'Comma') {
		const formattedValue = value.toFixed(2);

		if (separator === 'Space') {
			// Format with a space separator
			const [integerPart, decimalPart] = formattedValue.split('.');
			if (value > 1000) {
				return `${currencySymbol}${integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}${
					decimalPart ? `.${decimalPart}` : ''
				}`;
			}
			return `${currencySymbol} ${integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}${
				decimalPart ? `.${decimalPart}` : ''
			}`;
		}
		// Format with a comma separator using toLocaleString
		return `${currencySymbol} ${value.toLocaleString(undefined, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		})}`;
	}
	// Returns the value with two decimal places
	return `${currencySymbol} ${value.toFixed(2)}`;
};

export const setiFramesRefsHeightDynamically = myRefs => {
	myRefs.forEach((ref, _) => {
		const iframe = ref.current;

		const setIframeHeight = () => {
			if (iframe) {
				const iframeDocument = iframe?.contentDocument || iframe?.contentWindow?.document;
				iframe.style.height = `${iframeDocument?.body?.scrollHeight}px`;
			}
		};

		const handleLoad = () => {
			setIframeHeight();
		};

		const handleResize = () => {
			setIframeHeight();
		};

		if (iframe) {
			iframe.addEventListener('load', handleLoad);
		}
		window.addEventListener('resize', handleResize);

		// Cleanup the event listener when the component unmounts
		return () => {
			if (iframe) {
				iframe.removeEventListener('load', handleLoad);
			}
			window.removeEventListener('resize', handleResize);
		};
	});
};

export const getDisplayOneOptionAtTimeDetails = productInstanceId => {
	const selectedProduct = store
		.getState()
		.fuse.form.channelProducts.value?.find(prod => prod.product.instanceId === productInstanceId);
	if (selectedProduct?.product?.displayOneOptionAtATime) {
		const { DisplayOneOption, Options, DefaultValue } =
			JSON.parse(selectedProduct?.product?.displayOneOptionAtATime || '') || {};
		return {
			displayOneOption: DisplayOneOption,
			defaultValue: DefaultValue,
			options: Options
		};
	}
	return {};
};
