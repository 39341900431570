import * as context from 'api/base';
import { getNewBuyInsurancePathGeneric } from 'app/layouts/whitelabel/routes/generic/Helpers/HomePageHelper';
import store from 'app/store';

export const footerConfig = ({ companyDetailsResponse }) => {
	const infoSettings = context.getSettings();
	const channelProducts = store.getState()?.fuse.form.channelProducts;
	const referenceTokenDetails = store.getState()?.fuse.form.referenceTokenDetails;
	const { address, address2, address3, city, province, country, postalCode, contactNumber, contactEMail } =
		companyDetailsResponse?.value?.companyContactViewModel || {};
	const myHostPrefix = store.getState()?.fuse.form.mySite.hostPrefix;

	return {
		menus: [
			{
				id: 1,
				title: infoSettings.textFooterHeader1,
				menuItems: [
					{
						title: infoSettings.footerFaqText,
						url: '/faq',
						isVisible: infoSettings?.footerMenuFaqVisible === 'true'
					},
					{
						title: infoSettings.footerTermsAndCondText,
						url: '/terms-and-conditions',
						isVisible: infoSettings?.footerMenuTermsAndConditionsVisible === 'true'
					},
					{
						title: infoSettings.footerPrivacyPolicyText,
						url: '/privacy-policy',
						isVisible: infoSettings?.footerMenuPrivacyPolicyVisible === 'true'
					},
					{
						title: 'Disclosure',
						url: '/disclosure',
						isVisible: infoSettings?.footerMenuDisclosureVisible === 'true'
					},
					{
						title: infoSettings.footerDetailsText,
						url: '/details',
						isVisible: infoSettings?.footerMenuDetailsVisible === 'true'
					}
				],
				md: 2,
				xs: 6
			},
			{
				id: 2,
				title: infoSettings.textFooterHeader2,
				menuItems: [
					{
						title: infoSettings.footerContactText,
						url: '/contact',
						isVisible: infoSettings.footerMenuContactVisible === 'true'
					},
					{
						title: infoSettings.footerAboutText,
						url: '/about',
						isVisible: infoSettings.footerMenuAboutVisible === 'true'
					},
					{
						title: 'Buy Insurance',
						url: getNewBuyInsurancePathGeneric(channelProducts, referenceTokenDetails?.token, myHostPrefix),
						isVisible: infoSettings.footerMenuInsuranceVisible === 'true'
					},
					{
						title: 'Submit Claim',
						url: '/submit-claim',
						isVisible: infoSettings.footerMenuClaimVisible === 'true'
					}
				],
				md: 2,
				xs: 6
			},
			{
				id: 3,
				title: infoSettings.textAddressHeader,
				menuItems: [
					{
						title: `${address || ''} ${address2 || ''} ${address3 || ''} ${city || ''} ${province || ''} ${
							country || ''
						} ${postalCode || ''}`,
						url: '#',
						isVisible: true
					},
					{
						title: `${contactNumber || ''}`,
						url: contactNumber,
						isVisible: true
					},
					{
						title: `${contactEMail || ''}`,
						url: `mailTo:${contactEMail}`,
						isVisible: true
					}
				],
				md: 3,
				xs: 12
			}
		]
	};
};
