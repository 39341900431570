export const parameters = {
	serviceUrl: 'https://api-itp2-prod-za.azure-api.net/bl',
	captchaKey: '6Ldx83goAAAAABn9BMqEjSZ9N3YB-11UGQkitERa',
	brandLabelSubscriptionKey: 'af00ff8dcc3042bd84feddadac128eb0',
	bitpUrl: 'https://web-admin-itp2-prod-za.azurewebsites.net'

	// serviceUrl: 'http://localhost:7071',
	// serviceUrl: 'https://briisk-i-dev-api.azure-api.net/bl',
	// captchaKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
	// brandLabelSubscriptionKey: '6aeeca623d274b61bd75b25cd28d2cb8'
	// bitpUrl: 'https://briisk-i-dev-web.azurewebsites.net'
};
