export const useStylesNavbar = theme => {
	return {
		Divider: {
			width: '1.5px',
			margin: '10px 5px !important'
		},
		drawer: {
			backgroundColor: `${theme.palette.header.background} !important`,
			height: '100%',
			display: 'flex !important',
			textAlign: 'center',
			flexDirection: 'column',
			[theme.breakpoints.up(930)]: {
				display: 'none'
			}
		},
		LoginButton: {
			borderRadius: '5px !important',
			marginLeft: '5px',
			marginRight: '10px',
			boxShadow: '0 4px 8px rgb(0 0 0 / 10%)',
			fontSize: '1.3rem',
			fontWeight: '700 !important',
			height: '40px'
		},
		navItemsContainer: {
			display: 'none',
			[theme.breakpoints.up(930)]: {
				display: 'flex !important',
				justifyContent: 'flex-end !important',
				flexGrow: '1 !important'
			}
		},
		LoginButtonVertical: {
			margin: '25px 50px 0 87px',
			borderRadius: '5px !important'
		},
		appbar: {
			backgroundColor: `${theme.palette.header.background} !important`
		},
		navItemActiveText: {
			fontWeight: '700 !important',
			textTransform: 'none !important'
		},
		selected: {
			color: theme.palette.primary.main
		}
	};
};

export const useStylesNavMenuItem = theme => {
	return {
		root: {
			minHeight: 38,
			width: 'auto !important',
			'&.active': {
				backgroundColor: `${theme.palette.secondary.main}!important`,
				color: `${theme.palette.secondary.contrastText}!important`,
				pointerEvents: 'none',
				'& .nav-list-item-text-primary': {
					color: 'inherit'
				},
				'& .nav-list-item-icon': {
					color: 'inherit'
				}
			},
			'& .nav-list-item-icon': {
				display: 'none'
			},
			'& .nav-list-item-text': {
				padding: '0 !important'
			},
			'& .MuiListItemText-primary': {
				fontWeight: '700 !important',
				textTransform: 'none !important'
			},
			'&.nav-list-item.active': {
				backgroundColor: 'transparent !important',
				color: `${theme.palette.primary.main} !important`
			},
			'&.nav-list-item:nth-last-of-type(1)': {
				[theme.breakpoints.up(930)]: {
					backgroundColor: '#FFFFFF !important',
					boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
					borderRadius: '5px',
					marginLeft: '5px',
					marginRight: '0px',
					'&:hover': {
						backgroundColor: `${theme.palette.secondary.main} !important`
					}
				}
			},
			'&.nav-list-item:nth-last-of-type(2)': {
				[theme.breakpoints.up(930)]: {
					backgroundColor: `${theme.palette.primary.main} !important`,
					boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
					borderRadius: '5px',
					marginLeft: '5px',
					color: '#FFFFFF !important',
					'&:hover': {
						backgroundColor: `${theme.palette.secondary.main} !important`
					}
				}
			},
			borderRadius: '5px !important',
			color: `${theme.palette.text.primary} !important`,
			textDecoration: 'none !important'
		},
		navItemActiveText: {
			fontWeight: '700 !important',
			textTransform: 'none !important'
		}
	};
};

export const useStylesNavbarMenuDropdown = theme => {
	return {
		navItemActiveText: {
			fontWeight: '700 !important',
			textTransform: 'none !important'
		},
		root: {
			minHeight: 38,
			width: '100%',
			padding: '8px 16px !important',
			textAlign: 'left',
			'&.active': {
				backgroundColor: `${theme.palette.secondary.main} !important`,
				color: `${theme.palette.secondary.contrastText} !important`,
				pointerEvents: 'none',
				'& .nav-list-item-text-primary': {
					color: 'inherit',
					textTransform: 'CAPITALIZE'
				},
				'& .nav-list-item-icon': {
					color: 'inherit'
				}
			},
			'&.nav-list-item-text-primary': {
				color: `${theme.palette.text.primary} !important`,
				textTransform: 'CAPITALIZE'
			},
			'&.nav-list-item-text-primary.active': {
				color: `${theme.palette.primary.main} !important`,
				textTransform: 'CAPITALIZE'
			},
			'&.nav-list-item-icon': {},
			'&.nav-list-item-text': {
				padding: '0 !important'
			},
			'& .MuiListItemText-primary': {
				fontWeight: '700 !important',
				textTransform: 'none !important'
			},
			'&.nav-list-item.active': {
				backgroundColor: 'transparent !important',
				color: `${theme.palette.primary.main} !important`
			},
			'&.nav-list-item:nth-last-of-type(2)': {
				backgroundColor: `${theme.palette.primary.main} !important`,
				boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
				borderRadius: '5px',
				marginLeft: '5px',
				color: '#FFFFFF !important'
			},
			'&.nav-list-item:nth-last-of-type(1).active': {
				color: 'inherit !important'
			},

			color: `${theme.palette.text.primary} !important`,
			textDecoration: 'none!important',
			borderRadius: '5px !important'
		},
		selected: {
			color: theme.palette.primary.main
		}
	};
};

export const useStylesNavbarBadge = theme => {
	return {
		root: {
			padding: '0 7px',
			fontSize: 11,
			fontWeight: 600,
			height: 20,
			minWidth: 20,
			borderRadius: 20,
			display: 'flex',
			alignItems: 'center',
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText
		}
	};
};

export const useStylesNavBarLogo = (theme, logoWidth) => {
	return {
		root: {
			'& .logo-icon': {
				transition: theme.transitions.create(['width', 'height'], {
					duration: theme.transitions.duration.shortest,
					easing: theme.transitions.easing.easeInOut
				})
			},
			'& .react-badge, & .logo-text': {
				transition: theme.transitions.create('opacity', {
					duration: theme.transitions.duration.shortest,
					easing: theme.transitions.easing.easeInOut
				})
			}
		},
		reactBadge: {
			backgroundColor: '#121212',
			color: '#61DAFB'
		},
		logo: {
			margin: logoWidth !== 'auto' && 'auto',
			height: 'auto',
			width: logoWidth || 'auto',
			maxHeight: '60px'
		}
	};
};
