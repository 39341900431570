import clsx from 'clsx';
import { memo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppContext from 'app/AppContext';
import FooterLayout from 'app/layouts/whitelabel/components/footer/FooterLayout';
import TopbarWrapperLayout from 'app/layouts/whitelabel/components/topbar/TopbarWrapperLayout';
import FuseSuspense from '@briisk/core/FuseSuspense';
import Cookies from './components/cookies/Cookies';
import { getChannelSettingByKey } from './routes/generic/Helpers/GenericIndexHelper';
import NavLayout from './components/navbar/NavbarLayout';
import { useStylesWhiteLabel } from './routes/generic/Styles/CommonStyles';

function WhiteLabel(props) {
	const classes = useStylesWhiteLabel({ ...props });
	const iseditor = new URLSearchParams(useLocation().search).get('iseditor') === 'true';
	const isItForIFrame = getChannelSettingByKey('IS-IT-FOR-IFRAME') === 'true';

	return (
		<AppContext.Consumer>
			{({ routes }) => (
				<div id="fuse-layout" className="w-full flex flex" style={classes.root}>
					<div className="flex flex-col flex-auto min-w-0">
						<main id="fuse-main" className="flex flex-col flex-auto min-h-screen min-w-0 relative">
							{/* Navbar Top Section (Email & Social Media) */}
							{!isItForIFrame && !iseditor && <TopbarWrapperLayout className="fixed sticky top-0 z-50" />}

							{/* Navbar Section */}
							{!isItForIFrame && !iseditor && <NavLayout className={clsx('sticky top-0 z-50')} />}

							{/* Main or Middle Content Section */}
							<div className="flex flex-col flex-auto min-h-0 relative z-10">
								<FuseSuspense>
									<Routes>
										{routes.map((route, index) => (
											<Route key={index} path={route.path} element={route.element} />
										))}
									</Routes>
								</FuseSuspense>
								{props.children}
							</div>

							{/* Footer Section */}
							{!isItForIFrame && !iseditor && <FooterLayout />}

							{/* Cookies Section */}
							{!isItForIFrame && !iseditor && <Cookies />}
						</main>
					</div>
				</div>
			)}
		</AppContext.Consumer>
	);
}

export default memo(WhiteLabel);
