import store from 'app/store';
import { getCollectionType } from 'utils/getPaymentType';
import { getFactors } from './CreatePolicyHelper';
import { resumePolicyPathNames } from './ResumePolicyHelper';

export const productPriceRequestBody = (productInfo, risks, factors, values, serviceRisksWithValues = null) => {
	const channelProducts = store.getState()?.fuse?.form?.channelProducts?.value;
	const productPayment = store.getState()?.fuse?.form?.productPaymentResponse?.value?.productPayment;
	const searchPolicyResponse = store.getState()?.fuse.form?.searchPolicyResponse;
	const draftPolicy = store.getState()?.fuse?.form?.draftPolicy;

	const getCalculatorRisks = () => {
		const newRisks = [];
		// Reason - [18257] Filtering the risk's attributes in case of followings:
		// If the "value" key is found in the each attributes of the risks, then passing it to the API call.
		// If the "value" key is not found in any of the each attributes, then filtered out from the risks, hence NO values.
		// Might be optimized in future or add more functionality
		risks.forEach((risk, i) => {
			risk &&
				Object.keys(risk)
					.filter(rt => Object.keys(values).some(f => f.includes(rt)))
					.forEach((rk, j) => {
						newRisks.push({
							name: risk[rk].name,
							code: risk[rk].code,
							attributes: risk[rk].attributes.map(({ dataType, name, code }) => ({
								code,
								dataType,
								name,
								value: values[`${rk}-${name}`]
							}))
						});
					});
		});
		return newRisks;
	};

	// Returns the array of strings representing the payment collection types
	const getPaymentFrequencies = () => {
		const payloadFrequency = [];
		productPayment?.forEach(payment => {
			if (!payloadFrequency?.includes(getCollectionType(payment?.collectionType))) {
				payloadFrequency.push(getCollectionType(payment?.collectionType));
			}
		});
		return payloadFrequency;
	};

	// NOTE: Sending productFrequencies empty hence no longer necessary
	// Frequencies should be calculated from the rating engine, we may use getPaymentFrequenies if required
	let request = {
		executeCalculatorRequest: {
			risks: serviceRisksWithValues
				? [...getCalculatorRisks(), ...serviceRisksWithValues]
				: [...getCalculatorRisks()],
			factors: getFactors(values, factors),
			productFrequencies: []
		},
		productTypeReferences: [...new Set(productInfo.options.map(option => option.optionTypeInstanceId))],
		calculatorVersion: channelProducts?.find(product => product?.product?.instanceId === values?.productInstanceId)
			?.product?.calculatorVersion,
		collectionType: productPayment?.[0]?.collectionType,
		isProductPaymentAvailable: productPayment?.length
	};

	// Pulling the endDate from the searchPolicyResponse if it exists.
	const { value: { endDate: previousPolicyEndDate } = {} } = searchPolicyResponse || {};

	// Assigning the ratingAdditionalParameter
	request.executeCalculatorRequest.ratingAdditionalParameters =
		{
			// If previousPolicyEndDate is available, add it to the parameters.
			...(previousPolicyEndDate && { previousPolicyEndDate }),
			// If the draftPolicy's type is 'resumeUWCEndorsement', include the previousPolicyReference.
			...(draftPolicy?.draftType === resumePolicyPathNames.resumeUWCEndorsement && {
				previousPolicyReference: draftPolicy?.pathParam2
			})
		} || {};

	return request;
};

export const getProductPriceDetails = (
	productPriceNew,
	productOptionInstanceId,
	formValues = {},
	draftType = undefined
) => {
	if (!productPriceNew?.value) return {};
	const pOptions = productPriceNew?.value?.productOptions;
	const pOption = pOptions?.find(e => e.productOptionReference === productOptionInstanceId);
	if (!pOption) return { isValid: false };
	const breakUp = [
		...(pOption?.risks || []).map(risk => {
			return {
				name: risk?.name,
				ratings: [...(risk?.ratings || [])],
				code: risk?.code
			};
		})
	];
	const isValid = pOption?.ratings?.find(e => e?.total <= 0) ? false : pOption?.rule?.isValid;
	const ruleMessage = pOption?.rule?.message ? pOption?.rule?.message : 'Not available!';
	// When the user continuing referred policy, We should not worry about the review required.
	const isReviewRequired =
		draftType === resumePolicyPathNames.resumeUCReferredPolicy
			? false
			: pOption?.rule?.message?.includes('REVIEW REQUIRED') ||
				pOption?.risks?.find(e => e.rule?.ruleOutfit?.RuleStatusMessage?.toLowerCase() === 'review required');
	const ratings = [...(pOption?.ratings || [])];

	// Get the PHO risks from the breakUp array
	const pHORiskFromBreakUp = breakUp.find(risk => risk?.code === 'PHO');

	// Get the rating outfit for the PHO risks based on the selected collection frequency
	const pHORatingOutfit =
		pHORiskFromBreakUp?.ratings?.find(rating => rating?.frequency === formValues?.collsFrequency) || {};

	// Assigning ratingOutfit if the pHORatingOutfit has a value, else assigning the ratingOutfit from the breakUp array
	const ratingOutfit =
		pHORatingOutfit?.ratingOutfit ||
		(breakUp?.flatMap(br => br?.ratings) || [])?.find(rating => rating?.frequency === formValues?.collsFrequency)
			?.ratingOutfit ||
		{};
	return {
		isValid,
		ruleMessage,
		isReviewRequired,
		ratings,
		breakUp,
		ratingOutfit
	};
};

// This function execute sorting the product options according to isValid - true/false
export const sortProductOptions = (
	options,
	getProductPrice,
	productPriceNew,
	quotePolicyResponse,
	draftPolicy,
	values,
	displayOneOptionAtATime = null
) => {
	// If the displayOneOptionAtATime is true and there is a valid sliderValue,
	// we display only one option that matches the slider value.
	if (
		displayOneOptionAtATime?.displayOneOption &&
		values?.OneOptionDisplayValue &&
		displayOneOptionAtATime?.defaultValue
	) {
		// Finding the option that matches the slider value and returns valid product option
		const matchingOption =
			displayOneOptionAtATime.options?.find(
				option => parseInt(option?.['Value'], 10) === values?.OneOptionDisplayValue
			) || {};
		// Filter valid product options based on the matching instanceId
		if (matchingOption) {
			return options.filter(option => option.instanceId === matchingOption?.['InstanceId']) || [];
		}
	}
	if (productPriceNew?.status === 'success') {
		if (
			canOnlyShowTheSelectedOption({
				productOptionInstanceId: values?.productOptionInstanceId,
				quotePolicyResponse,
				draftPolicy
			})
		) {
			return options?.filter(option => option.instanceId === values.productOptionInstanceId);
		}
		// Sort based on the valid options.
		// We should show the valid options first & then the invalid options.
		return options?.sort((a, b) => {
			if (getProductPrice(b.instanceId)?.isValid) return 1;
			if (getProductPrice(a.instanceId)?.isValid) return -1;
			return 0;
		});
	}
	return options;
};

// This function returns a boolean true/false indicating whether only the selected option can be shown based on provided values.
// It returns true if only the selected option can be shown; otherwise, it returns false.
export const canOnlyShowTheSelectedOption = ({ productOptionInstanceId, quotePolicyResponse, draftPolicy }) => {
	// Check if a product option is selected and if quotePolicyResponse contains any values or keys length greater than one.
	// OR else, check if draftPolicy has a draftType and it is not equal to resumePolicyDraft.
	// Returns true if the condition is met; otherwise, returns false.
	return (
		(productOptionInstanceId && Object.keys(quotePolicyResponse || {})?.length > 0) ||
		(draftPolicy?.draftType && draftPolicy?.draftType !== resumePolicyPathNames.resumePolicyDraft)
	);
};
