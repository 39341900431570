/* eslint-disable prefer-const */
import moment from 'moment';

export const calculateDate = yearToBeMinus => {
	const date = moment();
	date.subtract(yearToBeMinus + 1, 'years')
		.add(1, 'month')
		.add(1, 'day');
	return new Date(date.format('MM/DD/YYYY'));
};

export const getChildDobMinMaxDate = returnDate => {
	const parsedDate = moment(returnDate).subtract(18, 'years').add(1, 'day').format('MM/DD/YYYY');
	let minDate;
	let maxDate;
	const date = new Date();
	date.setMonth(date.getMonth() - 6);
	maxDate = new Date(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
	minDate = new Date(`${parsedDate}`);
	return {
		minDate,
		maxDate
	};
};

export const getAdultDobMaxDate = departureDate => {
	const parsedDate = moment(departureDate).subtract(18, 'years').format('MM/DD/YYYY');
	return new Date(`${parsedDate}`);
};

export const getAdultDobMinMaxDate = (minYearToBeMinus, maxYearToBeMinus) => {
	let minDate;
	let maxDate;
	minDate = calculateDate(minYearToBeMinus);
	maxDate = calculateDate(maxYearToBeMinus);
	return {
		minDate,
		maxDate
	};
};

export const truncateTimeFromDate = value => {
	const date = value ? new Date(value) : new Date();
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	return `${day}-${month}-${year}`;
};

export const getDepartureDate = (departureDate, days) => {
	const date = departureDate ? new Date(departureDate) : new Date();
	date.setDate(date.getDate() + days);
	return new Date(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
};

export const getDaysDifference = (startDate, endDate) => {
	try {
		const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays;
	} catch {
		return 0;
	}
};

export const getRenderElementDate = (count, type = null) => {
	const value = parseInt(count.split('~')?.[0], 10);
	let period = 'years';
	switch (count.split('~')[1]?.toLowerCase()) {
		case 'd':
			period = 'days';
			break;
		case 'm':
			period = 'months';
			break;
		case 'y':
			period = 'years';
			break;
		default:
			period = 'years';
			break;
	}
	if (type === 'min') {
		return moment().add(value, period).add(1, 'day').format('YYYY-MM-DD');
	}
	return moment().add(value, period).format('YYYY-MM-DD');
};

// This function calculates a person's age based on their birth date.
// The input is expected to be in date format.
export const calculateAge = birthDate => {
	const formattedBirthDate = moment(birthDate, 'DD-MM-YYYY');
	const age = moment().diff(formattedBirthDate, 'years', false);
	return age;
};

// This function return the date in DD-MM-YYYY format
export const getDMYFormat = date => {
	return moment(date).format('DD-MM-YYYY');
};

// This function return parse the DMY format to ISO format
export const parseDMYFormat = (date, format) => {
	return moment(date, ['DD-MM-YYYY']).format(format);
};

// Function validates the dates
export const isDateValid = dateString => {
	return moment(dateString).isValid();
};

// This function converts the local date to UTC format
export const convertLocalToUTC = localTime => {
	const localDate = new Date(localTime);
	const offsetMinutes = localDate.getTimezoneOffset();
	const utcTimestamp = localDate.getTime() - offsetMinutes * 60000;
	const utcDate = new Date(utcTimestamp);
	return utcDate;
};
