export function getLogo() {
	let logoData = {
		primaryLogo: '',
		secondaryLogo: '',
		tertiaryLogo: '',
		tertiaryLogoText: ''
	};

	const store = require('app/store').default;
	const state = store?.getState();
	const wlData = state?.fuse?.form?.channelSettings?.value;

	wlData?.channelSettings?.forEach(function (item, index) {
		if (item.key === 'Primary-Logo') {
			if (item.value && item.value.length > 0) {
				logoData.primaryLogo = item.value;
			}
		} else if (item.key === 'Secondary-Logo') {
			if (item.value && item.value.length > 0) {
				logoData.secondaryLogo = item.value;
			}
		} else if (item.key === 'Tertiary-Logo') {
			if (item.value && item.value.length > 0) {
				logoData.tertiaryLogo = item.value;
			}
		} else if (item.key === 'Tertiary-Logo-Text') {
			if (item.value && item.value.length > 0) {
				logoData.tertiaryLogoText = item.value;
			}
		}
	});

	return logoData;
}

function getPageUrlFromKey(key) {
	if (!key) return '/';

	switch (key) {
		case 'TopMenu-Home-Text':
			return '/';
		case 'TopMenu-About-Text' || 'FooterMenu-About-Text':
			return '/about';
		case 'TopMenu-Contact-Text' || 'FooterMenu-Contact-Text':
			return '/contact';
		case 'TopMenu-FAQ-Text' || 'FooterMenu-FAQ-Text':
			return '/faq';
		case 'TopMenu-Claim-Text' || 'FooterMenu-Claim-Text':
			return '/submit-claim';
		default:
			return '/';
	}
}

export function getHeaderNav() {
	let navItems = [];

	const store = require('app/store').default;
	const state = store?.getState();
	const wlData = state?.fuse?.form?.channelSettings?.value;

	wlData?.channelContents?.forEach(function (item, index) {
		if (item.key.startsWith('TopMenu')) {
			let keys = item.key.split('-');
			let fName = keys[0] + '-' + keys[1];

			if (navItems.find(el => el.fullName === fName) === undefined) {
				switch (keys[2]) {
					case 'Text':
						navItems.push({
							fullName: keys[0] + '-' + keys[1],
							id: item.value.toLowerCase(),
							name: item.value,
							icon: '',
							type: 'item',
							url: getPageUrlFromKey(item.key),
							title: item.value,
							language: item.language,
							visible: ''
						});
						break;
					case 'Icon':
						navItems.push({
							fullName: keys[0] + '-' + keys[1],
							id: '',
							name: '',
							icon: item.value,
							type: 'item',
							url: '',
							title: '',
							language: item.language,
							visible: ''
						});
						break;
					case 'Visible':
						navItems.push({
							fullName: keys[0] + '-' + keys[1],
							id: '',
							name: '',
							icon: '',
							type: 'item',
							url: '',
							title: '',
							language: item.language,
							visible: item.value
						});
						break;
					default:
						break;
				}
			} else {
				navItems.forEach(function (navItem, navIndex) {
					if (navItems[navIndex].fullName === keys[0] + '-' + keys[1]) {
						switch (keys[2]) {
							case 'Text':
								navItems[navIndex] = {
									fullName: keys[0] + '-' + keys[1],
									id: item.value.toLowerCase(),
									name: item.value,
									icon: navItems[navIndex].icon,
									type: 'item',
									url: getPageUrlFromKey(item.key),
									title: item.value,
									language: item.language,
									visible: navItems[navIndex].visible
								};
								break;
							case 'Icon':
								navItems[navIndex] = {
									fullName: keys[0] + '-' + keys[1],
									id: navItems[navIndex].id,
									name: navItems[navIndex].name,
									icon: item.value,
									type: 'item',
									url: navItems[navIndex].url,
									title: navItems[navIndex].title,
									language: item.language,
									visible: navItems[navIndex].visible
								};
								break;
							case 'Visible':
								navItems[navIndex] = {
									fullName: keys[0] + '-' + keys[1],
									id: navItems[navIndex].id,
									name: navItems[navIndex].name,
									icon: navItems[navIndex].icon,
									type: 'item',
									url: navItems[navIndex].url,
									title: navItems[navIndex].title,
									language: item.language,
									visible: item.value
								};
								break;
							default:
								break;
						}
					}
				});
			}
		}
	});

	wlData?.channelSettings?.forEach(function (item, index) {
		if (item.key.startsWith('TopMenu')) {
			let keys = item.key.split('-');

			navItems.forEach(function (navItem, navIndex) {
				if (navItems[navIndex].fullName === keys[0] + '-' + keys[1]) {
					switch (keys[2]) {
						case 'Visible':
							navItems[navIndex] = {
								fullName: keys[0] + '-' + keys[1],
								id: navItems[navIndex].id,
								name: navItems[navIndex].name,
								icon: navItems[navIndex].icon,
								type: 'item',
								url: navItems[navIndex].url,
								title: navItems[navIndex].title,
								language: item.language,
								visible: item.value
							};
							break;
						default:
							break;
					}
				}
			});
		}
	});

	navItems = navItems.filter(e => e.visible !== 'false'); // will return ['A', 'C']

	return navItems;
}