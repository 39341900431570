import { memo } from 'react';
import { useTheme } from '@mui/material';
import WhiteLabel from './whitelabel/WhiteLabel';
import { useStyles } from './whitelabel/routes/generic/Styles/CommonStyles';

const Layouts = props => {
	const theme = useTheme();
	const classes = useStyles(theme);
	return <WhiteLabel classes={{ root: classes.root }} {...props} />;
};

export default memo(Layouts);
