/* eslint-disable no-throw-literal */
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import __ from 'lodash';
import { invertGetCollectionDaysType } from 'utils/getPaymentType';
import store from 'app/store';
import { parseDMYFormat } from 'utils/calculateDate';
import { getDeclarationsForPolicy } from './DeclarationHelper';

// Dependent Service Risks are the one which needed a API call to verify/perform something. In return, API will return all the attributes with the values.
// Independent Service Risks are the one which does not need a API call. We just have to assign a value to all the attributes from their linked attribute.
const getIndependentServiceRisks = (serviceRisks, values, risks, factors, getAttributeValueByCode) => {
	let result = [];
	serviceRisks.forEach(async (serviceRisk, i) => {
		// If the serviceRisk is dependent, then don't add to the result.
		// If the serviceRisk is independent, then add to the result.
		if (!values?.serviceRisks?.find(e => e.code === serviceRisk.code)) {
			// Add only when any of the attribute is linked.
			if (serviceRisk?.attributes?.some(e => e.linkedField)) {
				result = [
					...result,
					{
						productRiskReference: serviceRisk.instanceId,
						code: serviceRisk.code,
						externalReference: '',
						price: 0,
						discount: 0,
						tax: 0,
						total: 0,
						attributes: serviceRisk.attributes.map(attr => ({
							code: attr.code,
							name: attr.name,
							value: getAttributeValueByCode(
								null,
								attr?.linkedField?.split('~')[1]?.trim(),
								values,
								risks,
								factors
							)?.value,
							dataType: attr.dataType
						})),
						valuations: [],
						isValidServiceRisk: true
					}
				];
			}
		}
	});
	return result;
};

// optionPrice = Selected option price details from execute calculator response
export const getRisks = (values, risks, optionPrice, policiesCount = 1) => {
	let riskValues = [];
	risks.forEach(
		(risk, i) =>
			risk &&
			Object.keys(risk).forEach((field, j) => {
				const riskValuesPerCodeLength = riskValues?.filter(e => e.code === risk[field].code)?.length;

				const currentRiskOfOptionPriceRisks = optionPrice?.risks?.filter(e => e.code === risk[field].code)?.[
					riskValuesPerCodeLength
				];

				// The below condition is added because,
				// When we have duplicate or the same risk multiple times, let's say child risks,
				// We can't bind the risks with Risk Code becuase all those multiple risks carries same code.
				// Hence selectedOption.risks.ratings we have to bind 1st to 1st & 2nd to 2nd with create policy payload.
				const riskRating = currentRiskOfOptionPriceRisks?.ratings?.find(
					x => x.frequency === values.collsFrequency
				);

				riskValues.push({
					productRiskReference: risk[field].instanceId,
					code: risk[field].code,
					name: risk[field].name,
					externalReference: '',
					price: (() => {
						let priceValue;
						if (riskRating?.ratingOutfit?.apiPrice) {
							priceValue = parseFloat(riskRating.ratingOutfit.apiPrice);
						} else {
							priceValue = riskRating?.price ?? 0;
						}
						return priceValue / policiesCount;
					})(),
					discount: (() => {
						let discountValue;
						if (riskRating?.ratingOutfit?.apiDiscount) {
							discountValue = parseFloat(riskRating?.ratingOutfit?.apiDiscount);
						} else {
							discountValue = riskRating?.discount ?? 0;
						}
						return discountValue / policiesCount;
					})(),
					tax: (() => {
						let taxValue;
						if (riskRating?.ratingOutfit?.apiTax) {
							taxValue = parseFloat(riskRating?.ratingOutfit?.apiTax);
						} else {
							taxValue = riskRating?.tax ?? 0;
						}
						return taxValue / policiesCount;
					})(),
					total: (() => {
						let totalValue;
						if (riskRating?.ratingOutfit?.apiTotal) {
							totalValue = parseFloat(riskRating?.ratingOutfit?.apiTotal);
						} else {
							totalValue = riskRating?.total ?? 0;
						}
						return totalValue / policiesCount;
					})(),
					attributes: risk[field].attributes.map(attr => ({
						code: attr.code,
						name: attr.name,
						value: values[`${field}-${attr.name}`],
						dataType: attr.dataType
					})),
					valuations: currentRiskOfOptionPriceRisks?.valuations?.values ?? [],
					formType: risk[field]?.formType,
					riskType: risk[field]?.riskType
				});
			})
	);
	if (values?.serviceRisks) {
		riskValues = [...riskValues, ...(values?.serviceRisks || [])];
	} else {
		riskValues = [...riskValues];
	}

	return riskValues;
};

export const getFactors = (values, factors) => {
	const factorValues = [];
	Object.keys(factors).forEach(factor => {
		factors[factor].forEach(field => {
			factorValues.push({
				code: field.code,
				name: field.name,
				value: values[`${factor}-${field.name}`],
				dataType: field.dataType
			});
		});
	});
	return factorValues;
};

// First argument for default values, and second argument for required values of policyDraft
export const getPolicyDraftDetails = (
	{ formValues, factors, risks, phoneNumberCode, phoneNumber, referenceToken, optionPrice },
	{ email, reference, instanceId, isFromCreatePolicySale = false } = {}
) => {
	const risksPayload = getRisks({ ...formValues }, risks, optionPrice ?? null);
	const factorsPayload = getFactors({ ...formValues }, factors);
	const { activeStep, productInstanceId, riskPageNo, productOptionInstanceId, collsFrequency } = formValues;

	return {
		productInstanceId,
		factors: !isFromCreatePolicySale ? factorsPayload : [],
		risks: !isFromCreatePolicySale ? risksPayload : [],
		activeStep,
		productOptionInstanceId,
		collsFrequency,
		riskPageNo,
		redirectURL: window.location,
		phoneNumberCode,
		phoneNumber,
		referenceToken,
		email,
		reference,
		instanceId
	};
};

// NOTE: This method is for assigning the values in the service risk attributes from ratingOutfit
// Currently, we doing it for PBD risk.
const getPremiumBreakdownServiceRisks = (serviceRisks, ratingOutfit) => {
	// Finding the premium breakdown risks
	const premiumBreakdownRisk = serviceRisks?.find(risk => risk.code === 'PBD');
	if (premiumBreakdownRisk) {
		const updatedAttributes = premiumBreakdownRisk?.attributes.map(attribute => {
			// Converting the attribute name into camelcase to assign the values
			const attributeName = __.camelCase(attribute.name)?.replace('proRata', 'prorata');
			if (Object?.keys(ratingOutfit || {}).some(key => key === attributeName)) {
				return { ...attribute, value: ratingOutfit[attributeName] };
			}
			return attribute;
		});
		return [
			{
				attributes: updatedAttributes
					?.filter(att => att.value)
					?.map(attr => ({
						code: attr.code,
						name: attr.name,
						value: attr.value,
						dataType: attr.dataType
					})),
				productRiskReference: premiumBreakdownRisk?.instanceId,
				code: premiumBreakdownRisk.code,
				name: premiumBreakdownRisk.name,
				externalReference: '',
				valuations: []
			}
		];
	}
	return [];
};

export const createPolicyRequestBody = ({
	values,
	risks,
	factors,
	optionPrice,
	agentDetails,
	currencyCode,
	declaration,
	serviceRisks,
	getAttributeValueByCode,
	paymentInceptionDate,
	productLifeCycles,
	selectedCollectionTypeTotalPrice,
	policyStatus,
	policyReference = null, // Optional
	doNotValidate = false, // Optional,
	doNotAddPolicyCollectionSchedule = false, // Optional
	doNotAddDeclarations = false, // Optional
	ratingOutfit // Optional
}) => {
	try {
		// Validating Product Option...
		if (!doNotValidate) {
			if (!values?.collsIsSelectedProductOptionValid) {
				throw 'Selected option is not available.';
			} else if (!values?.collsTotalPrice || values?.collsTotalPrice <= 0) {
				throw 'Invalid total price.';
			}
		}

		const channelSettings = store.getState().fuse?.form?.channelSettings?.value;

		const failedAgentToken = store.getState().fuse?.form?.failedAgentTokens;

		const searchPolicyResponse = store.getState().fuse?.form?.searchPolicyResponse?.value;

		const selectedCollectionType = parseInt(values?.collsPaymentProvider?.collectionType, 10);

		const paymentRecurringCollectionDay = parseInt(
			invertGetCollectionDaysType(values?.collsPaymentRecurringCollectionDay),
			10
		);

		const nonBusinessDayRule = values?.collsPaymentProvider?.nonBusinessDayRule;

		const paymentFirstCollectionDate = parseDMYFormat(values?.collsPaymentFirstCollectionDate);

		const independentServiceRisks = getIndependentServiceRisks(
			serviceRisks,
			values,
			risks,
			factors,
			getAttributeValueByCode
		);

		const premiumBreakDownServiceRisks = getPremiumBreakdownServiceRisks(serviceRisks, ratingOutfit);

		const { attributes: serviceSummaryRiskAttributes } =
			(independentServiceRisks &&
				independentServiceRisks?.length > 0 &&
				independentServiceRisks?.find(e => e.code === 'SERVICESUM')) ||
			[];

		const TERMS = {
			0: 'none',
			1: 'hours',
			2: 'days',
			5: 'months',
			6: 'years'
		};

		const startDate =
			(serviceSummaryRiskAttributes && serviceSummaryRiskAttributes[0]?.value) ||
			(ratingOutfit?.policyStartDate &&
				moment(ratingOutfit?.policyStartDate, ['DD/MM/YYYY']).format('YYYY-MM-DDTHH:mm:ss[Z]')) ||
			(paymentInceptionDate && moment(paymentInceptionDate, ['DD-MM-YYYY']).format('YYYY-MM-DDTHH:mm:ss[Z]')) ||
			moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss[Z]');

		const endDate =
			(serviceSummaryRiskAttributes && serviceSummaryRiskAttributes[1]?.value) ||
			(ratingOutfit?.policyEndDate &&
				moment(ratingOutfit?.policyEndDate, ['DD/MM/YYYY']).format('YYYY-MM-DDTHH:mm:ss[Z]')) ||
			moment(paymentInceptionDate ?? new Date(), ['DD-MM-YYYY'])
				.add(productLifeCycles?.duration ?? 1, TERMS[productLifeCycles?.termDuration ?? 6])
				.subtract(1, 'day')
				.add({ hours: 23, minutes: 59, seconds: 59 })
				.format('YYYY-MM-DDTHH:mm:ss[Z]');

		// Get the policy holder risk from the calculator response of the selected option.
		const pho_OptionPriceRisk = optionPrice?.risks?.find(e => e?.code === 'PHO');

		// From the above pho_OptionPriceRisk, find any risk rating outfit that has number of policies count.
		const numberOfPolicies = Number(
			pho_OptionPriceRisk?.ratings?.find(x => x.ratingOutfit?.numberOfPolicies)?.ratingOutfit?.numberOfPolicies
		);

		// If numberOfPolicies > 1, then consider numberOfPolicies or always create one policy.
		const policiesCount = !numberOfPolicies || numberOfPolicies <= 0 ? 1 : numberOfPolicies;

		return {
			externalReference: uuidv4(),
			networkId: '',
			channelId: `${channelSettings?.channel?.id}`,
			companyId: agentDetails?.partnerCode || '',
			brokerId: '',
			agentId: agentDetails?.agentCode || '',
			comment: '',
			productId: values.productInstanceId,
			policies: Array.from({ length: policiesCount }, () => ({
				externalReference: uuidv4(),
				policyReference,
				existingPolicyReference:
					ratingOutfit?.isLinked === 'True' ? searchPolicyResponse?.policyReference ?? '' : '',
				productOptionReference: values?.productOptionInstanceId,
				startDate,
				endDate,
				status: policyStatus,
				currencyCode,
				otpReference: values[Object.keys(values)?.find(key => key.includes('otpDec'))]?.split('~')[1] ?? '',
				comment: failedAgentToken?.token ? `Failed token - ${failedAgentToken?.token}` : '',
				originAttributes: JSON.stringify({
					redirectURL: window.location,
					collections: { collsFrequency: values?.collsFrequency }
				}),
				risks: [
					...getRisks(values, risks, optionPrice, policiesCount),
					...independentServiceRisks,
					...premiumBreakDownServiceRisks
				],
				attributes: getFactors(values, factors),
				declarations: !doNotAddDeclarations ? [...getDeclarationsForPolicy(declaration, values)] : [],
				policyCollectionSchedule: !doNotAddPolicyCollectionSchedule
					? {
							collectionDay: paymentRecurringCollectionDay || 1, // Recurring Payment Day
							collectionDuration: productLifeCycles?.termDuration, // Life Cycle - Term & Periods - Term Duration
							collectionFrequency: selectedCollectionType,
							collectionNonBusinessDayRule: nonBusinessDayRule,
							policyFirstCollection: {
								firstCollectionAmount: parseFloat(selectedCollectionTypeTotalPrice),
								firstCollectionDate: paymentFirstCollectionDate, // First Payment Date
								isTaxInclusive: true,
								firstCollectionTaxAmount: 0,
								comments: ''
							}
						}
					: null
			}))
		};
	} catch (ex) {
		throw ex?.message || ex;
	}
};
