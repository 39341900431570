import { Navigate } from 'react-router-dom';
import Config from '../layouts/whitelabel/routes/Config';

const routeConfigs = [Config];

const setRoutes = (config, defaultAuth) => {
	let routes = [...config.routes];

	routes = routes.map(route => {
		let auth = config.auth || config.auth === null ? config.auth : defaultAuth || null;
		auth = route.auth || route.auth === null ? route.auth : auth;
		const settings = { ...config.settings, ...route.settings };

		return {
			...route,
			settings,
			auth
		};
	});

	return [...routes];
};

const generateRoutesFromConfigs = (configs, defaultAuth) => {
	let allRoutes = [];
	configs.forEach(config => {
		allRoutes = [...allRoutes, ...setRoutes(config, defaultAuth)];
	});
	return allRoutes;
};

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		element: () => <Navigate to="/" />
	},
	{
		path: '/product',
		element: () => <Navigate to="/product" />
	},
	{
		path: '/product-options',
		element: () => <Navigate to="/product-options" />
	},
	{
		path: '/faq',
		element: () => <Navigate to="/faq" />
	},
	{
		path: '/fast-facts',
		element: () => <Navigate to="/fast-facts" />
	},
	{
		path: '/about',
		element: () => <Navigate to="/about" />
	},
	{
		path: '/contact',
		element: () => <Navigate to="/contact" />
	},
	{
		path: '/privacy-policy',
		element: () => <Navigate to="/privacy-policy" />
	},
	{
		path: '/loading-error',
		element: () => <Navigate to="/loading-error" />
	}
];

export default routes;
