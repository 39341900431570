import queryString from 'query-string';
import store from 'app/store';

export const setParam = (navigate, token) => {
	if (!token) return;

	const hostPrefix = store?.getState()?.fuse?.form?.mySite?.hostPrefix ?? null;

	navigate({
		search: `?${queryString.stringify({
			token,
			...(hostPrefix && { site: hostPrefix }),
			...queryString.parse(window.location.search)
		})}`,
		state: window.location.state
	});
};

export const getPathParamByName = (url, pathParam) => {
	const winLocHref = new URL(url);
	const urlSearchParams = new URLSearchParams(winLocHref.search);
	return urlSearchParams.get(pathParam);
};
