import { tableCellClasses } from '@mui/material';

export const useStyles = theme => {
	return {
		container: {
			width: '100%',
			backgroundColor: 'white',
			position: 'fixed',
			bottom: 0,
			zIndex: 100,
			padding: `15px 7vw`
		},
		title: {
			fontSize: '18px',
			fontWeight: '700',
			textAlign: 'left',
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center'
			}
		},
		content: {
			textAlign: 'left',
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center'
			}
		},
		cardButton: {
			borderRadius: '5px !important',
			margin: '5px 5px !important',
			fontSize: '12px !important',
			[theme.breakpoints.down('sm')]: {
				margin: 'auto'
			}
		},
		btnContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'space-around',
				padding: '15px 0px'
			}
		},
		acceptButton: {
			borderRadius: '5px !important',
			margin: '5px 5px !important',
			fontSize: '12px !important',
			[theme.breakpoints.down('sm')]: {
				margin: 'auto'
			}
		},
		paper: {
			backgroundColor: 'rgba(254, 232, 229, 0.8)'
		},
		summaryContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
			padding: '0px 20px'
		},
		table: {
			width: '100%'
		},
		dialogContainer: {
			backgroundColor: 'rgba(254, 254, 254, 0.8) !important',
			padding: '20px',
			[theme.breakpoints.down('sm')]: {
				padding: '20px 0px'
			}
		},
		dialogTitle: {
			padding: '0px 20px'
		},
		showMoreText: {
			color: 'black',
			fontSize: 14
		},
		showMoreHyper: {
			textDecorationColor: theme.palette.primary.main,
			textDecoration: 'underline',
			cursor: 'pointer'
		},
		save: {
			backgroundColor: theme.palette.primary.main,
			color: 'white',
			padding: '10px',
			borderRadius: 5
		},
		contentText: {
			color: 'black'
		}
	};
};

export const antSwitchStyle = theme => {
	return {
		width: 38,
		height: 20,
		padding: 0,
		display: 'flex',
		'&:active': {
			'& .MuiSwitch-thumb': {
				width: 25
			},
			'& .MuiSwitch-switchBase.Mui-checked': {
				transform: 'translateX(10px)'
			}
		},
		'& .MuiSwitch-switchBase': {
			padding: 3,
			'&.Mui-checked': {
				transform: 'translateX(12px)',
				left: 6,
				color: '#fff',
				'& + .MuiSwitch-track': {
					opacity: 1,
					backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
				}
			}
		},
		'& .MuiSwitch-thumb': {
			boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
			width: 14,
			height: 14,
			borderRadius: 8,
			transition: theme.transitions.create(['width'], {
				duration: 200
			})
		},
		'& .MuiSwitch-track': {
			borderRadius: 22 / 2,
			opacity: 1,
			backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
			boxSizing: 'border-box'
		}
	};
};

export const tabCellStyle = theme => {
	return {
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: '#424C67',
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	};
};

export const tabRowStyle = () => {
	return {
		'&:nth-of-type(odd)': {
			backgroundColor: '#C8D1D9'
		},
		'&:nth-of-type(even)': {
			backgroundColor: '#C8D1D9'
		},
		'&:last-child td, &:last-child th': {
			border: 0
		}
	};
};

export const accordionStyle = () => {
	return {
		'&:not(:last-child)': {
			borderBottom: 0
		},
		'&:before': {
			display: 'none'
		},
		'& .MuiAccordionSummary-root': {
			backgroundColor: '#F2F2F2 !important'
		},
		'& .MuiAccordionSummary-root.Mui-expanded': {
			backgroundColor: '#F2F2F2 !important'
		},
		'& .Mui-expanded > div > p': {
			color: 'black !important'
		},
		'& .Mui-expanded > svg': {
			backgroundColor: '#F2F2F2 !important',
			color: 'black !important'
		},
		'& .MuiPaper-elevation': {
			backgroundColor: '#FEFEFECC !important'
		},
		'& .MuiPaper-root': {
			backgroundColor: 'pink !important'
		}
	};
};

export const accordionSummaryStyle = () => {
	return {
		flexDirection: 'row-reverse',
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(90deg)'
		},
		'& .MuiAccordionSummary-content': {}
	};
};

export const accordionDetailsStyle = theme => {
	return {
		padding: theme.spacing(2),
		borderTop: '1px solid rgba(0, 0, 0, .125)'
	};
};

export const accordionArrow = () => {
	return {
		fontSize: '0.9rem',
		color: 'black'
	};
};

export const accordionDividerStyle = () => {
	return {
		height: '10px'
	};
};

export const switchStyle = value => {
	return {
		marginLeft: '8px',
		'& .MuiSwitch-track': {
			bgcolor: value ? '#4caf50' : '#bdbdbd'
		},
		'& .MuiSwitch-thumb': {
			bgcolor: value ? '#f5f5f5' : '#f5f5f5'
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			color: value ? '#4caf50' : ''
		},
		'& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
			bgcolor: value ? '#4caf50' : ''
		}
	};
};
