/* eslint-disable */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './SocialMediaIcons.css';
import * as context from 'api/base';
import store from 'app/store';

const SocialMediaIcons = () => {
	const pageSettings = context.getSettings();
	const companyDetailsResponse = store.getState()?.fuse.form.companyDetailsResponse;
	const { facebook, twitter, instagram, youtube, linkedIn } =
		companyDetailsResponse?.value?.companyContactViewModel || {};

	return (
		<ul className="p-0 list-none pb-5">
			{facebook && pageSettings.facebookLinkVisible === 'true' && (
				<li
					className="social-media_list_main-item"
					onClick={() => window.open(`${facebook}`, '_blank')}
					onKeyDown={() => {}}
					tabIndex={0}
				>
					<FontAwesomeIcon icon={faFacebookF} size="lg" />
				</li>
			)}
			{twitter && pageSettings.twitterLinkVisible === 'true' && (
				<li
					className="social-media_list_main-item"
					onClick={() => window.open(`${twitter}`, '_blank')}
					onKeyDown={() => {}}
					tabIndex={1}
				>
					<FontAwesomeIcon icon={faXTwitter} size="xl" />
				</li>
			)}
			{instagram && pageSettings.instagramLinkVisible === 'true' && (
				<li
					className="social-media_list_main-item"
					onClick={() => window.open(`${instagram}`, '_blank')}
					onKeyDown={() => {}}
					tabIndex={2}
				>
					<FontAwesomeIcon icon={faInstagram} size="xl" />
				</li>
			)}
			{youtube && pageSettings.youtubeLinkVisible === 'true' && (
				<li
					className="social-media_list_main-item"
					onClick={() => window.open(`${youtube}`, '_blank')}
					onKeyDown={() => {}}
					tabIndex={3}
				>
					<FontAwesomeIcon icon={faYoutube} size="xl" />
				</li>
			)}
			{linkedIn && pageSettings.linkedInLinkVisible === 'true' && (
				<li
					className="social-media_list_main-item"
					onClick={() => window.open(`${linkedIn}`, '_blank')}
					onKeyDown={() => {}}
					tabIndex={4}
				>
					<FontAwesomeIcon icon={faLinkedin} size="xl" />
				</li>
			)}
		</ul>
	);
};

export default SocialMediaIcons;
