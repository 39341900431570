/* eslint-disable */
var { host } = window.location;
// const host = 'dev.demoinsurance.com';

export function getProducts(url) {
	let products = [];

	const store = require('app/store').default;
	const state = store?.getState();
	const wlProd = state?.fuse?.form?.channelProducts?.value;

	wlProd?.forEach(pitem => {
		let prodContents = {};
		let options = [];
		let faqs = [];

		if (url === pitem.product.instanceId || url === undefined) {
			pitem?.options?.forEach(function (item, index) {
				let contents = {};
				Array.prototype.forEach.call(item.contents, citem => {
					contents[citem.key] = citem.value;
				});

				options.push({
					instanceId: item.option.instanceId,
					name: item.option.name,
					description: item.option.description,
					icon: item.option.iconName,
					excludedRisks: item.option.excludedRisks,
					contents: contents,
					productIcon: pitem.contents.filter(e => e.key === 'PRODUCT-ICON')[0]?.value || null,
					optionTypeInstanceId: item.type.instanceId
				});
			});

			pitem.contents.forEach(function (item, index) {
				prodContents[item.key] = item.value;
			});

			pitem.faqs.forEach(function (item, index) {
				faqs.push({
					order: item.order,
					question: item.question,
					answer: item.answer
				});
			});

			let risks = [];
			Array.prototype.forEach.call(pitem.risks, ritem => {
				let attributes = [];
				Array.prototype.forEach.call(ritem.attributes, aitem => {
					let settings = [];
					if (aitem.settings != 'null') {
						let parsedJson = JSON.parse(aitem.settings);
						Array.prototype.forEach.call(parsedJson.items, sitem => {
							settings.push(sitem);
						});
					}

					attributes.push({
						name: aitem.name,
						description: aitem.description,
						instanceId: aitem.instanceId,
						type: aitem.dataType,
						sequence: aitem.sequence,
						isRequired: aitem.isRequired,
						quickQuote: aitem.quickQuote,
						code: aitem.code,
						settings: settings
					});

					attributes = attributes.sort(function (a, b) {
						return a.sequence - b.sequence;
					});
				});

				risks.push({
					code: ritem.code,
					iconName: ritem.iconName,
					primaryColour: ritem.primaryColour,
					sequence: ritem.sequence,
					minCount: ritem.minCount,
					maxCount: ritem.maxCount,
					quickQuote: ritem.quickQuote,
					attributes: attributes,
					instanceId: ritem.instanceId
				});
			});

			let _attributes = [];
			Array.prototype.forEach.call(pitem.attributes, attribute => {
				let settings = [];
				if (attribute.settings) {
					let parsedJson = JSON.parse(attribute.settings);
					parsedJson &&
						Array.prototype.forEach.call(parsedJson.items, sitem => {
							settings.push(sitem);
						});
				}

				_attributes.push({
					code: attribute.code,
					name: attribute.name,
					description: attribute.description,
					type: attribute.dataType,
					sequence: attribute.sequence,
					isRequired: attribute.isRequired,
					quickQuote: attribute.quickQuote,
					settings: settings
				});
			});

			products.push({
				instanceId: pitem.product.instanceId,
				name: pitem.product.name,
				description: pitem.product.description,
				icon: pitem.product.iconName,
				options: options,
				faqs: faqs,
				risks: risks,
				contents: prodContents,
				attributes: _attributes
			});
		}
	});

	return products;
}

export function getProductOptions(url) {
	let productOptions = [];

	const store = require('app/store').default;
	const state = store?.getState();
	const wlProd = state?.fuse?.form?.channelProducts?.value;

	wlProd?.forEach(pitem => {
		let productInstanceId = pitem.product.instanceId;
		if (url === pitem.product.instanceId || url === undefined) {
			pitem.options.forEach(function (item, index) {
				let contents = {};
				Array.prototype.forEach.call(item.contents, citem => {
					contents[citem.key] = citem.value;
				});

				productOptions.push({
					instanceId: item.option.instanceId,
					name: item.option.name,
					description: item.option.description,
					icon: item.option.iconName,
					productInstanceId: productInstanceId,
					contents: contents,
					productIcon: pitem.contents.filter(e => e.key === 'PRODUCT-ICON')[0]?.value || null
				});
			});
		}
	});
	return productOptions;
}

export function parseContent(content) {
	// It will add \n to every HTML end tag
	const formattedHTML = content?.replace(/<\/\w+>/g, match => `${match}\n`);
	const parser = new DOMParser();
	return parser.parseFromString(formattedHTML, 'text/html');
}

export function getPageData(page) {
	let pageData = {
		url: '',
		content: ''
	};

	const store = require('app/store').default;
	const state = store?.getState();
	const wlData = state?.fuse?.form?.channelSettings?.value;

	wlData?.channelPages?.forEach(function (item, index) {
		if (item.pageLink === page) {
			pageData.url = item.pageLink;
			pageData.content = item.htmlContent;
			parseContent(item.htmlContent);
		}
	});

	return pageData;
}

export function getPageInfo() {
	const store = require('app/store').default;
	const state = store?.getState();
	const wlData = state?.fuse?.form?.channelSettings?.value;

	let data = {
		pageTitle: wlData?.channel?.name,
		pageDescription: wlData?.company?.description,
		host: host,
		company: wlData?.company
	};

	return data;
}

export function getSettings() {
	let data = {
		channelFaq: [],
		formStepNameQuote: 'Quote',
		formStepNameProductOptionSelection: 'Product Option Selection',
		formStepNameUnderwriting: 'Underwriting Details',
		formStepNameDeclarations: 'Declarations',
		formStepNameAttachments: 'Attachments',
		formStepNameProductSelection: 'Product Selection',
		formStepNameOverview: 'Overview',
		formStepNameSearchPolicy: 'Search Policy',
		riskHeader1FontColour: '#000000',
		riskHeader2BackgroundColour: '#746a6a',
		riskHeader2FontColor: '#ffffff',
		pauseAndResumeEnabled: false
	};

	const store = require('app/store').default;
	const state = store?.getState();
	const wlData = state?.fuse?.form?.channelSettings?.value;
	const wlProd = state?.fuse?.form?.channelProducts?.value;

	wlData?.channelSettings?.forEach(function (item, index) {
		switch (item.key) {
			case 'Facebook-Visible':
				data.facebookLinkVisible = item.value;
				break;
			case 'X-Visible':
				data.twitterLinkVisible = item.value;
				break;
			case 'LinkedIn-Visible':
				data.linkedInLinkVisible = item.value;
				break;
			case 'YouTube-Visible':
				data.youtubeLinkVisible = item.value;
				break;
			case 'Instagram-Visible':
				data.instagramLinkVisible = item.value;
				break;
			case 'Country-Currency-Code':
				data.currencyCode = item.value;
				break;
			case 'Country-Currency-Symbol':
				data.currencySymbol = item.value;
				break;
			case 'UI-Mobile-Flow-Type':
				data.mobileFlowView = item.value;
				break;
			case 'UI-Desktop-Flow-Type':
				data.desktopFlowView = item.value;
				break;
			case 'Main-Header-Color':
				data.headerBackground = item.value;
				break;
			case 'Top-Section-Contact-Bar-Background-Colour':
				data.topSectionContactBarBackgroundColor = item.value;
				break;
			case 'Top-Section-Contact-Bar-Font-Colour':
				data.topSectionContactBarFontColor = item.value;
				break;
			case 'Form-Step-Name-Quote':
				data.formStepNameQuote = item.value;
				break;
			case 'Form-Step-Name-Product-Option-Selection':
				data.formStepNameProductOptionSelection = item.value;
				break;
			case 'Form-Step-Name-Underwriting':
				data.formStepNameUnderwriting = item.value;
				break;
			case 'Form-Step-Name-Declarations':
				data.formStepNameDeclarations = item.value;
				break;
			case 'Form-Step-Name-Attachments':
				data.formStepNameAttachments = item.value;
				break;
			case 'Form-Step-Name-Product-Selection':
				data.formStepNameProductSelection = item.value;
				break;
			case 'Display-help-text-as-icon':
				data.displayHelpTextAsIcon = item.value;
				break;
			case 'Risk-Header-1-Font-Colour':
				data.riskHeader1FontColour = item.value;
				break;
			case 'Risk-Header-2-Background-Colour':
				data.riskHeader2BackgroundColour = item.value;
				break;
			case 'Risk-Header-2-Font-Colour':
				data.riskHeader2FontColor = item.value;
				break;
			case 'Display-Non-Opted-Risk':
				data.displayNonOptedRisk = item.value;
				break;
			case 'Pause-And-Resume-Enabled':
				data.pauseAndResumeEnabled = item.value;
				break;
			case 'Display-Policy-Summary-On-Payment-Page':
				data.displayPolicySummaryOnPaymentPage = item.value;
				break;
			case 'Flow-Type':
				data.flowType = item.value;
				break;
			case 'Footer-Second-Background-Color':
				data.footerSecondBackgroundColor = item.value;
				break;
			case 'Footer-Third-Background-Color':
				data.footerThirdBackgroundColor = item.value;
				break;
			case 'Form-Step-Name-Overview':
				data.formStepNameOverview = item.value;
				break;
			case 'Main-Primary-Color':
				data.mainPrimaryColor = item.value;
				break;
			case 'Main-Secondary-Color':
				data.mainSecondaryColor = item.value;
				break;
			case 'Main-Font-Color':
				data.mainFontColor = item.value;
				break;
			case 'Main-Background-Color':
				data.mainBackgroundColor = item.value;
				break;
			case 'Primary-Logo':
				data.primaryLogo = item.value;
				break;
			case 'Secondary-Logo':
				data.secondaryLogo = item.value;
				break;
			case 'FooterMenu-Background-Color':
				data.footerBackgroundColor = item.value;
				break;
			case 'FooterMenu-Header-Color':
				data.footerHeaderColor = item.value;
				break;
			case 'FooterMenu-Text-Color':
				data.footerTextColor = item.value;
				break;
			case 'ProductCard-Background-Color':
				data.productCardBackgroundColor = item.value;
				break;
			case 'ProductCard-Header-Color':
				data.productCardHeaderColor = item.value;
				break;
			case 'ProductCard-Header-Font-Color':
				data.productCardHeaderFontColor = item.value;
				break;
			case 'FooterMenu-FAQ-Visible':
				data.footerMenuFaqVisible = item.value;
				break;
			case 'FooterMenu-Terms-And-Conditions-Visible':
				data.footerMenuTermsAndConditionsVisible = item.value;
				break;
			case 'FooterMenu-Details-Visible':
				data.footerMenuDetailsVisible = item.value;
				break;
			case 'FooterMenu-PrivacyPolicy-Visible':
				data.footerMenuPrivacyPolicyVisible = item.value;
				break;
			case 'FooterMenu-Disclosure-Visible':
				data.footerMenuDisclosureVisible = item.value;
				break;
			case 'FooterMenu-Contact-Visible':
				data.footerMenuContactVisible = item.value;
				break;
			case 'FooterMenu-About-Visible':
				data.footerMenuAboutVisible = item.value;
				break;
			case 'FooterMenu-Insurance-Visible':
				data.footerMenuInsuranceVisible = item.value;
				break;
			case 'FooterMenu-Claim-Visible':
				data.footerMenuClaimVisible = item.value;
				break;
			case 'Search-Policy-Step-Name':
				data.formStepNameSearchPolicy = item.value;
				break;
		}
	});

	wlData?.channelContents?.forEach(function (item, index) {
		switch (item.key) {
			case 'FooterMenu-Header1-Text':
				data.textFooterHeader1 = item.value;
				break;
			case 'FooterMenu-Header2-Text':
				data.textFooterHeader2 = item.value;
				break;
			case 'FooterMenu-Address-Header':
				data.textAddressHeader = item.value;
				break;
			case 'Phone-Header':
				data.textPhoneHeader = item.value;
				break;
			case 'FooterMenu-Contact-Text':
				data.footerContactText = item.value;
				break;
			case 'FooterMenu-terms-and-conditions-Text':
				data.footerTermsAndCondText = item.value;
				break;
			case 'FooterMenu-details-Text':
				data.footerDetailsText = item.value;
				break;
			case 'FooterMenu-PrivacyPolicy-Text':
				data.footerPrivacyPolicyText = item.value;
				break;
			case 'FooterMenu-About-Text':
				data.footerAboutText = item.value;
				break;
			case 'FooterMenu-FAQ-Text':
				data.footerFaqText = item.value;
				break;
			case 'Default-Channel-icon':
				data.defaultChannelIcon = item.value;
				break;
		}
	});

	wlData?.channelFaqs?.forEach(function (item, index) {
		if (item.isEnabled) {
			data.channelFaq.push({
				id: (index + 1).toString(),
				question: item.question,
				answer: item.answer
			});
		}
	});

	data.prodInstance = wlProd?.[0]?.product?.instanceId;
	data.name = wlProd?.[0]?.product?.name;

	return data;
}

// To get Channel Host details
export function getChannelHost() {
	const store = require('app/store').default;
	const state = store?.getState();
	const mySiteHost = state?.fuse?.form?.mySite?.host;
	const wlData = state?.fuse?.form?.channelSettings?.value;
	const channelHost = wlData?.channelHosts?.find(e => e?.host === mySiteHost);
	return {
		tabName: channelHost?.channelName ? channelHost.channelName : 'Briisk - Instant Transaction Platform',
		favIconUrl: channelHost?.faviconImage,
		hostUrl: channelHost?.siteloadImage ? channelHost.siteloadImage : '/assets/images/logos/briisk.svg',
		defaultToken: channelHost?.defaultToken || null
	};
}
