export const getDeclarationsForPolicy = (declaration, values) => {
	let declarations = [];
	declaration?.value?.forEach((dec, i) => {
		if (dec.acceptType === 1) {
			declarations = [
				...declarations,
				{
					declarationAcceptType: dec.acceptType,
					declarationId: dec.instanceId,
					name: dec.name,
					value: values[`checkboxDec${i}`] ?? ''
				}
			];
		}
		if (dec.acceptType === 2) {
			declarations = [
				...declarations,
				{
					declarationAcceptType: dec.acceptType,
					declarationId: dec.instanceId,
					name: dec.name,
					value: values[`otpDec${i}`]?.split('~')[0] ?? ''
				}
			];
		}
		if (dec.acceptType === 3) {
			declarations = [
				...declarations,
				{
					declarationAcceptType: dec.acceptType,
					declarationId: dec.instanceId,
					name: dec.name,
					value: values[`signDec${i}`] ?? ''
				}
			];
		}
	});
	return declarations;
};
