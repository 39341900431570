import React from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import createTheme from './theme';

const ThemeProvider = ({ children }) => {
	const theme = createTheme();
	return (
		<MUIThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
		</MUIThemeProvider>
	);
};

export default ThemeProvider;
