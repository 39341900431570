import clsx from 'clsx';
import { useSelector } from 'react-redux';
import * as context from 'api/base';
import { Container, styled } from '@mui/material';
import SocialMediaIcons from '../../../shared-components/SocialMediaIcons/SocialMediaIcons';

const Root = styled('div')(({ theme, backgroundColor, color }) => ({
	background: backgroundColor ?? '#5a737a',
	color: color ?? '#FFFFFF',
	marginBottom: 10
}));

const link = {
	color: '#ffffff'
};

const TopbarLayout = props => {
	const settings = context.getSettings();
	const companyDetailsResponse = useSelector(({ fuse }) => fuse.form.companyDetailsResponse);
	const { contactNumber, contactEMail } = companyDetailsResponse?.value?.companyContactViewModel || {};

	return (
		<Root
			className={clsx('w-full h-64 min-h-28 max-h-28 shadow-md m-0', props.className)}
			backgroundColor={settings.topSectionContactBarBackgroundColor}
			color={settings.topSectionContactBarFontColor}
		>
			<Container className="flex flex-auto justify-between items-center w-full h-full container z-9999">
				<div className="flex flex-shrink-0 items-center">
					{contactEMail && (
						<div className="mx-auto">
							<a style={link} href={`mailto:${contactEMail}`}>
								{' '}
								{contactEMail}{' '}
							</a>
						</div>
					)}
					{contactNumber && (
						<div className="mx-40">
							<span style={link}>{contactNumber}</span>
						</div>
					)}
				</div>
				<SocialMediaIcons />
			</Container>
		</Root>
	);
};

export default TopbarLayout;
