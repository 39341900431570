import moment from 'moment';
import { updateDraftPolicy } from 'app/store/briisk/actions/formAction';
import store from 'app/store';
import { getPolicyDraftDetails } from './CreatePolicyHelper';
import { resumePolicyPathNames } from './ResumePolicyHelper';

export const generateReferenceNumber = () => {
	const format = 'DDMMYYhhmmss';
	return moment().format(format);
};

export const getPolicyDraftPayload = ({
	email,
	mobileNumber,
	instanceId,
	ProductInstanceId,
	draftDetails,
	LeadStatus,
	agentSellingCode,
	reference,
	draftType // Optional
}) => {
	const payload = {
		email,
		mobileNumber,
		instanceId,
		ProductInstanceId,
		draftDetails,
		LeadStatus,
		reference: reference || generateReferenceNumber(),
		isExpired: false,
		otpReference: null,
		agentSellingCode,
		link: window.location.origin,
		draftType
	};
	return payload;
};

// This function for updating the draftPolicy when increment of the active steps, this function needs to be optimized with status
export const updatePolicyDraft = ({ agentSellingCode, factors, risks, draftPolicy, dispatch, optionPrice }) => {
	const { requestBody, resumePolicyResponse, draftType } = draftPolicy;
	const formValues = store.getState()?.fuse?.form?.formValues;

	// Executing update policy draft only when resume policy draft
	if (draftType === resumePolicyPathNames.resumePolicyDraft) {
		if (!Object.keys(requestBody || resumePolicyResponse || {})?.length) {
			return;
		}

		const { phoneNumberCode, phoneNumber, email, reference, instanceId } = requestBody ?? resumePolicyResponse;

		const policyDraftDetails = getPolicyDraftDetails(
			{
				formValues,
				factors,
				risks,
				phoneNumberCode,
				phoneNumber,
				referenceToken: agentSellingCode,
				optionPrice
			},
			{
				email,
				reference,
				instanceId
			}
		);

		const policyDraftPayload = getPolicyDraftPayload({
			email,
			mobileNumber: `+${phoneNumberCode} ${phoneNumber}`,
			instanceId,
			ProductInstanceId: policyDraftDetails?.productInstanceId,
			draftDetails: JSON.stringify(policyDraftDetails),
			LeadStatus: 1,
			agentSellingCode,
			reference
		});

		dispatch(updateDraftPolicy(policyDraftPayload));
	}
};

export const isResumedPolicy = draftPolicy => {
	return (
		(draftPolicy?.resumePolicyResponse || draftPolicy?.requestBody) &&
		(draftPolicy?.draftType === resumePolicyPathNames.resumePolicyDraft ||
			draftPolicy?.draftType === resumePolicyPathNames.resumeUCReferredPolicy ||
			draftPolicy?.draftType === resumePolicyPathNames.resumeUWCReferredPolicy ||
			draftPolicy?.draftType === resumePolicyPathNames.resumeUCQuotePolicy)
	);
};
