import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Container, useTheme } from '@mui/material';
import Logo from 'app/layouts/whitelabel/components/navbar/Logo';
import * as context from 'api/base';
import { getHeaderNav } from '../helpers/Generichelper';
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from 'utils/useWindowDimensions';
import NavLinkAdapter from '@briisk/core/NavLinkAdapter';
import clsx from 'clsx';
import NavMenuDropdown from 'app/layouts/whitelabel/components/navbar/NavMenuDropdown';
import { clearForm } from 'app/store/briisk/formSlice';
import { useStylesNavMenuItem, useStylesNavbar } from '../styles/NavbarStyles';
import { getChannelSettingByKey } from '../../routes/generic/Helpers/GenericIndexHelper';
import { getNewBuyInsurancePathGeneric } from '../../routes/generic/Helpers/HomePageHelper';
import { LoginButton } from '../../routes/generic/Components/LoginButton';

const drawerWidth = 240;

const NavLayout = props => {
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const theme = useTheme();
	const classes = useStylesNavbar(theme);
	const hideHeaderMenuItems = getChannelSettingByKey('HIDE-HEADER-MENU-ITEMS') || 'false';
	const enableLogin = getChannelSettingByKey('Enable-Login') || 'false';
	const prodInfo = context.getProducts(props.productId);
	const channelProducts = useSelector(({ fuse }) => fuse.form.channelProducts);
	const referenceTokenDetails = useSelector(({ fuse }) => fuse.form.referenceTokenDetails);
	const [navItems, setNavItems] = useState([]);
	const { width } = useWindowDimensions();
	const myHostPrefix = useSelector(({ fuse }) => fuse.form?.mySite?.hostPrefix);
	const menuClasses = useStylesNavMenuItem(theme);
	const dispatch = useDispatch();

	const handleDrawerToggle = () => {
		setMobileOpen(prevState => !prevState);
	};

	const navigation = getHeaderNav();

	const consolidatingProductOptions = info => {
		return info
			.filter((obj, index, self) => index === self.findIndex(t => t.type.name === obj.type.name))
			.map(t => t.type);
	};

	const DROPDOWN_ENUMS = {
		DISPLAY_PRODUCTS: 'Display Products',
		DISPLAY_TYPES: 'Display Types',
		DISPLAY_OPTIONS: 'Display Options'
	};

	const topMenuProductsDD = getChannelSettingByKey('Top-Menu-Products-DD') || 'Display Products';

	useEffect(() => {
		const arr = [];
		navigation.forEach((_item, i) => {
			if (_item.fullName === 'TopMenu-Home') {
				arr[0] = { ..._item, exact: true };
			}

			if (_item.fullName === 'TopMenu-About') {
				arr[2] = { ..._item };
			}

			if (_item.fullName === 'TopMenu-FAQ') {
				arr[3] = { ..._item };
			}

			if (_item.fullName === 'TopMenu-Contact') {
				arr[4] = { ..._item };
			}

			if (_item.fullName === 'TopMenu-Insurance') {
				arr[5] = { ..._item };
			}

			if (_item.fullName === 'TopMenu-Claim') {
				arr[6] = { ..._item };
			}

			if (prodInfo && prodInfo.length > 0) {
				arr[1] = {
					fullName: 'TopMenu-Product-DD',
					icon: 'home',
					id: 'product',
					language: undefined,
					name: 'Product(s)',
					title: 'Product',
					type: 'dropdown',
					url: undefined,
					visible: 'true',
					children: (() => {
						let navDrop = [];
						channelProducts.value?.forEach(info => {
							if (
								DROPDOWN_ENUMS.DISPLAY_PRODUCTS === topMenuProductsDD ||
								DROPDOWN_ENUMS.DISPLAY_TYPES === topMenuProductsDD ||
								DROPDOWN_ENUMS.DISPLAY_OPTIONS === topMenuProductsDD
							) {
								navDrop = [
									...navDrop,
									{
										id: info.product.instanceId,
										name: info.product.name,
										title: info.product.name,
										type: 'dropdown-header',
										url: `/description/${info.product.instanceId}`,
										visible: 'true',
										nestedLevel: 1
									}
								];
							}

							if (
								DROPDOWN_ENUMS.DISPLAY_TYPES === topMenuProductsDD ||
								DROPDOWN_ENUMS.DISPLAY_OPTIONS === topMenuProductsDD
							) {
								consolidatingProductOptions(info.options)?.forEach((dt, index) => {
									navDrop = [
										...navDrop,
										{
											id: dt.instanceId,
											name: dt.name,
											title: dt.name,
											type: 'dropdown-subheader',
											url: ``,
											visible: 'true',
											nestedLevel: index
										}
									];
									if (DROPDOWN_ENUMS.DISPLAY_OPTIONS === topMenuProductsDD)
										info.options
											.filter(t => t.type.instanceId === dt.instanceId)
											.forEach(opt => {
												navDrop = [
													...navDrop,
													{
														id: opt.option.instanceId,
														name: opt.option.name,
														title: opt.option.name,
														type: 'dropdown',
														url: `/description/${info.product.instanceId}/${opt.type.instanceId}/${opt.option.instanceId}`,
														visible: 'true',
														nestedLevel: index
													}
												];
											});
								});
							}
						});
						return navDrop;
					})()
				};
			}

			setNavItems(arr);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearCache = async name => {
		if (name === 'TopMenu-Insurance') {
			await dispatch(
				clearForm({
					canClearRisk: false,
					canClearFactor: false,
					canClearPrimaryMemFields: false,
					canClearBenFields: false,
					canClearInitalApiCallsResponse: false
				})
			);
		}
	};

	const drawer = (
		<Box className={classes.drawer}>
			<Logo logoWidth="45%" />
			<Divider />
			<List>
				{navItems.map((item, idx) => {
					if (item.type === 'dropdown') {
						return (
							<NavMenuDropdown
								item={item}
								classes={classes}
								onCloseCollapse={() => setMobileOpen(false)}
								key={idx}
							/>
						);
					}
					return (
						<ListItem
							key={idx}
							className={clsx('nav-list-item')}
							sx={menuClasses.root}
							component={NavLinkAdapter}
							activeclassname="active"
							to={
								item.fullName === 'TopMenu-Insurance'
									? getNewBuyInsurancePathGeneric(
											channelProducts,
											referenceTokenDetails?.token,
											myHostPrefix
										)
									: item.url || ''
							}
							exact={`${item.exact}`}
							onClick={() => {
								handleDrawerToggle();
								clearCache(item.fullName);
							}}
						>
							<ListItemButton style={{ textAlign: 'center' }}>
								<ListItemText
									classes={{
										primary: clsx('text-13', menuClasses.navItemActiveText)
									}}
									className="nav-list-item-text text-center"
									primary={item.title}
								/>
							</ListItemButton>
						</ListItem>
					);
				})}
			</List>
			{enableLogin === 'true' && mobileOpen && (
				<>
					<Divider />
					<LoginButton className={classes.LoginButtonVertical} />
				</>
			)}{' '}
		</Box>
	);

	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<Box>
			<CssBaseline />
			<AppBar component="nav" position="static" sx={classes.appbar}>
				<Container>
					<Toolbar>
						{hideHeaderMenuItems === 'false' && (
							<IconButton
								color="white"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								sx={{ mr: 2, display: { lg: 'none' } }}
							>
								<MenuIcon />
							</IconButton>
						)}
						<Box sx={{ flexGrow: 1 }}>
							<Logo className="mx-4" logoWidth="auto" />
						</Box>
						{hideHeaderMenuItems === 'false' && (
							<>
								<Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }}>
									{navItems.map((item, idx) => {
										if (item.type === 'dropdown') {
											return <NavMenuDropdown item={item} classes={classes} key={idx} />;
										}

										return (
											<ListItem
												key={idx}
												sx={menuClasses.root}
												className={clsx('nav-list-item')}
												component={NavLinkAdapter}
												activeclassname="active"
												to={
													item.fullName === 'TopMenu-Insurance'
														? getNewBuyInsurancePathGeneric(
																channelProducts,
																referenceTokenDetails?.token,
																myHostPrefix
															)
														: item.url || ''
												}
												exact={`${item.exact}`}
												onClick={() => clearCache(item.fullName)}
											>
												<ListItemText
													className="nav-list-item-text text-center"
													primary={item.title}
													classes={{
														primary: clsx('text-13', menuClasses.navItemActiveText)
													}}
												/>
											</ListItem>
										);
									})}
								</Box>
								{enableLogin === 'true' && width >= 1200 && (
									<>
										<Divider orientation="vertical" flexItem sx={classes.Divider} />
										<LoginButton className={classes.LoginButton} />
									</>
								)}
							</>
						)}
					</Toolbar>
				</Container>
			</AppBar>
			<nav>
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true
					}}
					sx={{
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: drawerWidth,
							backgroundColor: classes?.drawer?.backgroundColor
						}
					}}
				>
					{drawer}
				</Drawer>
			</nav>
		</Box>
	);
};

export default NavLayout;
