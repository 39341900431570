import { Menu, MenuItem, Button, ListItemText, useTheme } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import useWindowDimensions from 'utils/useWindowDimensions';
import PropTypes, { exact } from 'prop-types';
import { useStylesNavbarMenuDropdown } from '../styles/NavbarStyles';
import { getRandomNumber } from 'utils/utils';

const NavMenuDropdown = ({ item, onCloseCollapse }) => {
	const theme = useTheme();
	const classes = useStylesNavbarMenuDropdown(theme);
	const location = useLocation();
	const [anchorEl, setAnchorEl] = useState(null);
	const { width } = useWindowDimensions();
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);

		// This function onCloseCollapse only called when layout becomes MobileView
		// We're checking the width of current layout, if width < 768, function will be execute.
		// THIS NEEDS TO BE OPTIMIZED through parent component.
		if (width < 768) {
			onCloseCollapse();
		}
	};

	return (
		<div>
			<Button
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				className={clsx('nav-list-item')}
				sx={classes.root}
			>
				<ListItemText
					className={`nav-list-item-text text-center`}
					primary={item.name}
					classes={{
						primary: clsx('text-13')
					}}
					sx={classes.navItemActiveText}
				/>
			</Button>
			{item.children && (
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button'
					}}
					style={{ maxHeight: '300px' }}
				>
					{item.children.map(it => {
						if (it.type === 'dropdown-header') {
							return (
								<Link
									to={it.url}
									exact={`${exact}`}
									className="text-black-600 no-underline"
									key={getRandomNumber()}
								>
									<div className="font-semibold no-underline p-10">{it.title}</div>
								</Link>
							);
						}
						if (it.type === 'dropdown-subheader') {
							return (
								<MenuItem
									key={getRandomNumber()}
									disabled
									onClick={handleClose}
									className="menu_item_disabled"
									sx={{
										paddingLeft: 3
									}}
								>
									<Link to={it.url} exact={`${exact}`} className="text-black-600 no-underline">
										<ListItemText
											className={`nav-list-item-text no-underline ${
												location.pathname.includes(it.url) ? classes.selected : ''
											}`}
											primary={it.title}
											classes={{
												primary: clsx('text-12 text-black font-bold', classes.navItemActiveText)
											}}
										/>
									</Link>
								</MenuItem>
							);
						}
						return (
							<MenuItem
								key={getRandomNumber()}
								onClick={handleClose}
								sx={{
									paddingLeft: 5
								}}
							>
								<Link to={it.url} exact={`${exact}`} className="text-gray-600 no-underline">
									<ListItemText
										className={`nav-list-item-text ${
											location.pathname.includes(it.url) ? classes.selected : ''
										}`}
										primary={`●${'  '}${it.title}`}
										classes={{
											primary: clsx('text-11 font-bold', classes.navItemActiveText)
										}}
									/>
								</Link>
							</MenuItem>
						);
					})}
				</Menu>
			)}
		</div>
	);
};

NavMenuDropdown.propTypes = {
	item: PropTypes.object,
	onCloseCollapse: PropTypes.func
};

export default NavMenuDropdown;
