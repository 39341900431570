import moment from 'moment';
import { getChannelSettingByKey } from 'app/layouts/whitelabel/routes/generic/Helpers/GenericIndexHelper';
import { parseDMYFormat } from './calculateDate';

const PaymentType = {
	None: 1,
	Card: 2,
	Bank: 4,
	Wallet: 8,
	External: 16,
	Cash: 32,
	Confirm: 64,
	Mobile: 128,
	CSC: 256,
	DebitOrder: 512,
	Retail: 1024,
	Invoice: 2048
};

export const getPaymentType = paymentType => {
	switch (paymentType) {
		case PaymentType.None:
			return 'None';
		case PaymentType.Card:
			return 'Card';
		case PaymentType.Bank:
			return 'Bank';
		case PaymentType.Wallet:
			return 'Wallet';
		case PaymentType.External:
			return 'External';
		case PaymentType.Cash:
			return 'Cash';
		case PaymentType.Confirm:
			return 'Confirm';
		case PaymentType.Mobile:
			return 'Mobile';
		case PaymentType.CSC:
			return 'CSC';
		case PaymentType.DebitOrder:
			return 'Debit Order';
		case PaymentType.Retail:
			return 'Retail';
		case PaymentType.Invoice:
			return 'Invoice';
		default:
			return 'NONE';
	}
};

export const getCheckoutType = (isJustCardTokenization, provider) => {
	if (!provider?.paymentType) return false;

	switch (provider.paymentType) {
		case 2: // card
			return isJustCardTokenization === 'true' ? 0 : 1;
		case 512: // debit order
			return 2;
		case 128: // mobile
			return 4;
		default:
			return 0;
	}
};

export const getCollectionType = collectionType => {
	switch (collectionType) {
		case 0:
			return 'OnceOff';
		case 1:
			return 'Hourly';
		case 2:
			return 'Daily';
		case 3:
			return 'Monthly';
		case 4:
			return 'Quarterly';
		case 5:
			return 'SemiAnnually';
		case 6:
			return 'Annually';
		default:
			return 'NONE';
	}
};

export const CollectionDaysType = {
	All: 0,
	First: 1,
	Last: 32,
	Second: 2,
	Third: 3,
	Fourth: 4,
	Fifth: 5,
	Sixth: 6,
	Seventh: 7,
	Eighth: 8,
	Ninth: 9,
	Tenth: 10,
	Eleventh: 11,
	Twelfth: 12,
	Thirteenth: 13,
	Fourteenth: 14,
	Fifteenth: 15,
	Sixteenth: 16,
	Seventeenth: 17,
	Eighteenth: 18,
	Nineteenth: 19,
	Twentieth: 20,
	TwentyFirst: 21,
	TwentySecond: 22,
	TwentyThrid: 23,
	TwentyFourth: 24,
	TwentyFifth: 25,
	TwentySixth: 26,
	TwentySeventh: 27,
	TwentyEighth: 28,
	TwentyNinth: 29,
	Thirtieth: 30,
	ThirtiethFirst: 31
};

export const getCollectionDaysType = collectionDay => {
	switch (collectionDay) {
		case CollectionDaysType.All:
			return 'All Days Of The Month';
		case CollectionDaysType.First:
			return '1st';
		case CollectionDaysType.Last:
			return 'Last Day Of The Month';
		case CollectionDaysType.Second:
			return '2nd';
		case CollectionDaysType.Third:
			return '3rd';
		case CollectionDaysType.Fourth:
			return '4th';
		case CollectionDaysType.Fifth:
			return '5th';
		case CollectionDaysType.Sixth:
			return '6th';
		case CollectionDaysType.Seventh:
			return '7th';
		case CollectionDaysType.Eighth:
			return '8th';
		case CollectionDaysType.Ninth:
			return '9th';
		case CollectionDaysType.Tenth:
			return '10th';
		case CollectionDaysType.Eleventh:
			return '11th';
		case CollectionDaysType.Twelfth:
			return '12th';
		case CollectionDaysType.Thirteenth:
			return '13th';
		case CollectionDaysType.Fourteenth:
			return '14th';
		case CollectionDaysType.Fifteenth:
			return '15th';
		case CollectionDaysType.Sixteenth:
			return '16th';
		case CollectionDaysType.Seventeenth:
			return '17th';
		case CollectionDaysType.Eighteenth:
			return '18th';
		case CollectionDaysType.Nineteenth:
			return '19th';
		case CollectionDaysType.Twentieth:
			return '20th';
		case CollectionDaysType.TwentyFirst:
			return '21st';
		case CollectionDaysType.TwentySecond:
			return '22nd';
		case CollectionDaysType.TwentyThrid:
			return '23rd';
		case CollectionDaysType.TwentyFourth:
			return '24th';
		case CollectionDaysType.TwentyFifth:
			return '25th';
		case CollectionDaysType.TwentySixth:
			return '26th';
		case CollectionDaysType.TwentySeventh:
			return '27th';
		case CollectionDaysType.TwentyEighth:
			return '28th';
		case CollectionDaysType.TwentyNinth:
			return '29th';
		case CollectionDaysType.Thirtieth:
			return '30th';
		case CollectionDaysType.ThirtiethFirst:
			return '31st';
		default:
			return 'NONE';
	}
};

export const invertGetCollectionDaysType = collectionDaysType => {
	if (collectionDaysType === 'Last Day Of The Month') return 32;
	return collectionDaysType?.replace(/(\d+)(st|nd|rd|th)/, '$1');
};

// eslint-disable-next-line consistent-return
function isLastDayOfMonth(date) {
	if (date instanceof Date) {
		const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
		return date.getDate() === lastDay;
	}
}

export const getRecurringCollectionDaysList = (collectionDays, firstCollectionDate) => {
	collectionDays?.split(',')?.forEach(opt => {
		// Where 0 = All Days Of The Month.
		if (parseInt(opt.trim(), 10) === 0) {
			collectionDays = '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,32';
		}
	});
	// eslint-disable-next-line prefer-const
	let collectionList = [];

	// Applying derived date of firstCollectionDate when the recurring day is hidden
	if (getChannelSettingByKey('Display-Recurring-Payment-Day') === 'false') {
		const derivedDate = moment(firstCollectionDate, ['DD-MM-YYYY']).format('DD');
		if (isLastDayOfMonth(new Date(parseDMYFormat(firstCollectionDate)))) {
			collectionList.push(getCollectionDaysType(32));
			return collectionList;
		}
		collectionList.push(getCollectionDaysType(parseInt(derivedDate.trim(), 10)));
		return collectionList;
	}
	collectionDays?.split(',')?.forEach(opt => {
		collectionList.push(getCollectionDaysType(parseInt(opt.trim(), 10)));
	});
	return collectionList;
};

export const NonBusinessDayRule = {
	none: 0,
	customerdecision: 1,
	collectpostnonbusinessday: 2,
	collectprenonbusinessday: 3
};

export const getNonBusinessDayRuleType = businessDayRule => {
	switch (businessDayRule) {
		case NonBusinessDayRule.none:
			return 'None';
		case NonBusinessDayRule.customerdecision:
			return 'Customer decision';
		case NonBusinessDayRule.collectpostnonbusinessday:
			return 'Next business day';
		case NonBusinessDayRule.collectprenonbusinessday:
			return 'Pre business day';
		default:
			return 'NONE';
	}
};
