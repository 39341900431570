import 'api';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import Layouts from './layouts/Layouts';
import AppContext from './AppContext';
import { Auth } from './auth';
import routes from './configs/routesConfig';
import store from './store';
import 'react-toastify/dist/ReactToastify.css';
import BackToTopButton from './layouts/whitelabel/routes/generic/Components/BackToTopButton';
import ThemeProvider from './theme/ThemeProvider';
// importing this package in order to check for the validity of all props passed in the components
import isPropValid from '@emotion/is-prop-valid';
// importing this helper component in order to modify how the styles are being processed
import { StyleSheetManager } from 'styled-components';

// console.log = () => {};
// console.error = () => {};
// console.warn = () => {};

function App() {
	const history = createBrowserHistory();
	const persistor = persistStore(store);

	return (
		<AppContext.Provider
			value={{
				routes
			}}
		>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Router history={history}>
						<Auth>
							{/* adding StyleSheetManager and setting it's shouldForwardProp to isPropValid  */}
							<StyleSheetManager shouldForwardProp={isPropValid} disableVendorPrefixes={false}>
								<ThemeProvider>
									<Layouts />
									<ToastContainer position="top-right" autoClose={1000} hideProgressBar />
									<BackToTopButton />
								</ThemeProvider>
							</StyleSheetManager>
						</Auth>
					</Router>
				</PersistGate>
			</Provider>
		</AppContext.Provider>
	);
}

export default App;
